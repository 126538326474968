import { Box } from '@chakra-ui/layout';
import { Select, SingleValue } from 'chakra-react-select';
import { isNull } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export type ToggleSelectOption = {
  index: number;
  value: string;
  label: string;
};

interface ToggleSelectProps {
  filterName: string;
  onChange?: (e: number) => void;
  initialIsCurrent: boolean;
}

export const ToggleSelect = ({
  filterName = '',
  onChange,
  initialIsCurrent,
}: ToggleSelectProps) => {
  const options = useMemo(
    () => [
      {
        index: 0,
        value: `Current ${filterName}`,
        label: `Current ${filterName}`,
      },
      {
        index: 1,
        value: `Previous ${filterName}`,
        label: `Previous ${filterName}`,
      },
    ],
    [filterName]
  );

  useEffect(() => {
    setSelectedOption(initialIsCurrent ? options[0] : options[1]);
  }, [initialIsCurrent, options]);

  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: string;
  } | null>(initialIsCurrent ? options[0] : options[1]);

  const availableOptions = selectedOption?.value.includes('Current')
    ? [options[1]]
    : [options[0]];

  return (
    <Box mt={2} w="100%">
      <Select
        value={
          selectedOption?.value.includes('Current') ? options[0] : options[1]
        }
        options={availableOptions}
        onChange={(e: SingleValue<ToggleSelectOption>) => {
          if (isNull(e)) return;
          setSelectedOption(e);
          onChange?.(e.index);
        }}
        size="sm"
        isSearchable={false}
        variant="filled"
        blurInputOnSelect
        focusBorderColor="gray.100"
        chakraStyles={{
          control: (prev) => ({
            ...prev,
            fontSize: '12px',
            '&:focus-within': {
              background: 'gray.100',
            },
            '&:hover': {
              cursor: 'pointer',
            },
          }),
          inputContainer: (prev) => ({
            ...prev,
            color: 'red',
          }),
          menu: (prev) => ({
            ...prev,
            padding: '0px',
            boxShadow: 'var(--chakra-shadows-2xl)',
          }),
          menuList: (prev) => ({
            ...prev,
          }),
          indicatorsContainer: (prev) => ({
            ...prev,
          }),
          dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
            ...prev,
            background: 'none',
            '> svg': {
              transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
            },
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          option: (prev) => ({
            ...prev,
            fontSize: '12px',
            background: 'white',
            '&:hover': {
              background: 'gray.100',
            },
          }),
        }}
      />
    </Box>
  );
};
