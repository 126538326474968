import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  PopoverBody,
  PopoverFooter,
  Tooltip,
} from '@chakra-ui/react';
import { AsyncCreatableSelect, MultiValue } from 'chakra-react-select';
import { SelectFooter } from '../select-footer';
import { useTalentDiscoveryFilter } from '../td-filter-provider';
import {
  TalentDiscoveryFilterOption,
  isFreeTextFilter,
  isFreeTextFilterState,
} from '../types';
import { Text } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { TDNameInput } from '@revelio/filtering';

interface FreeTextFilterProps {
  closeMenu: () => void;
  selectedFilter: TalentDiscoveryFilterOption;
  filterId?: string;
}

interface KeywordOption {
  label: string;
  value: string;
}

export const FreeTextFilter = ({
  closeMenu,
  selectedFilter,
  filterId,
}: FreeTextFilterProps) => {
  const { state, dispatch } = useTalentDiscoveryFilter();

  const isEditing = !!filterId || !selectedFilter.supportsMultiple;

  const selectedFreeTextState = (() => {
    if (isEditing) {
      return state.filters?.find((filter) =>
        !selectedFilter.supportsMultiple
          ? filter.name === selectedFilter.value
          : isFreeTextFilterState(filter) && filter.id === filterId
      );
    }

    return undefined;
  })();
  const isAFreeTextFilter = isFreeTextFilterState(selectedFreeTextState);

  const [keywords, setKeywords] = useState<KeywordOption[]>([]);

  useEffect(() => {
    if (selectedFreeTextState && isAFreeTextFilter) {
      const existingKeywords = selectedFreeTextState.text;
      setKeywords(
        existingKeywords.map((keyword: string) => ({
          label: keyword,
          value: keyword,
        }))
      );
    } else {
      setKeywords([]);
    }
  }, [selectedFreeTextState, isAFreeTextFilter]);

  const handleChange = (selectedOptions: MultiValue<KeywordOption>) => {
    setKeywords(selectedOptions as KeywordOption[]);
  };

  const handleClearSelections = () => {
    setKeywords([]);
  };

  const handleAddFilter = () => {
    const keywordValues = keywords.map((keyword) => keyword.value);
    if (isFreeTextFilter(selectedFilter.value)) {
      const newId = filterId || `${Date.now()}`;

      const text =
        selectedFilter.value === 'name' ? [name.name] : keywordValues;

      dispatch({
        type: 'ADD_FREE_TEXT_FILTER',
        name: selectedFilter.value,
        text,
        id: selectedFilter.supportsMultiple ? newId : null,
      });
    }
    closeMenu();
  };

  // Handle pasting comma-separated values
  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    const pastedValues = paste.split(',').map((val) => val.trim());
    const newOptions = pastedValues.map((value) => ({
      value,
      label: value,
    }));
    setKeywords((prevKeywords) => [...prevKeywords, ...newOptions]);
  };

  const initialName = (() => {
    if (selectedFilter.value === 'name' && isAFreeTextFilter) {
      return selectedFreeTextState?.text?.[0] || '';
    }

    return '';
  })();

  const [name, setName] = useState<{
    [key: string]: string;
  }>({ name: initialName });

  const setNameState = (name: { [key: string]: string }) => {
    setName(name);
  };

  const isAddDisabled =
    (selectedFilter.value === 'name' && name?.name?.length === 0) ??
    keywords.length === 0;

  return (
    <>
      <PopoverBody padding={0}>
        {selectedFilter.value !== 'name' && (
          <Flex alignItems="center" mr={6}>
            <Text flex="none" fontSize="sm" my={1} mr={2}>
              Contains any of
            </Text>
            <Tooltip
              hasArrow
              label="Type or paste a list of comma separated words"
              variant="label"
              placement="top"
              maxWidth="170px"
              fontSize="xs"
              lineHeight="15px"
              openDelay={400}
              p={2}
            >
              <InfoOutlineIcon boxSize={3} />
            </Tooltip>
          </Flex>
        )}
        <Box onClick={(e) => e.stopPropagation()} mt={1}>
          <Box onPaste={handlePaste} data-testid="free-text-input">
            {selectedFilter.value === 'name' ? (
              <TDNameInput
                state={name}
                setState={setNameState}
                vStackProps={{ my: -2 }}
                isTypeaheadDisabled={false}
              />
            ) : (
              <AsyncCreatableSelect
                isMulti
                value={keywords}
                onChange={handleChange}
                placeholder={selectedFilter.placeholder}
                formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                noOptionsMessage={() => null}
                components={{
                  ClearIndicator: () => null,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                inputId="free-text-input-id"
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                tagVariant="keywordChip"
                chakraStyles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderWidth: '1px',
                    borderColor: state.isFocused ? 'green.500' : 'gray.200',
                    boxShadow: 'none',
                    '&:hover': {
                      borderColor: 'green.500',
                    },
                    minHeight: '32px',
                    height: 'auto',
                    borderRadius: '0',
                    maxWidth: '100%',
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: 'blue.50',
                    border: '1px solid',
                    borderColor: 'blue.200',
                    borderRadius: 'md',
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: 'blue.700',
                  }),
                  multiValueRemove: (provided) => ({
                    ...provided,
                    color: 'blue.700',
                    '&:hover': {
                      backgroundColor: 'blue.100',
                      color: 'blue.800',
                    },
                  }),
                  input: (provided) => ({
                    ...provided,
                    margin: '0',
                    padding: '0',
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: '0 8px',
                  }),
                }}
              />
            )}
          </Box>
        </Box>
      </PopoverBody>
      <PopoverFooter padding="8px 0 0" borderTopWidth={0}>
        <SelectFooter
          onClearSelections={handleClearSelections}
          onClose={closeMenu}
          onAdd={handleAddFilter}
          addLabel={selectedFreeTextState ? 'Update' : 'Add'}
          isAddDisabled={isAddDisabled}
        />
      </PopoverFooter>
    </>
  );
};
