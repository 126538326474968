import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import {
  initialTalentDiscoveryFilterState,
  TalentDiscoveryFilterAction,
  talentDiscoveryFilterReducer,
  TalentDiscoveryFilterState,
} from './td-filter-reducer';

const TalentDiscoveryFilterContext = createContext<{
  state: TalentDiscoveryFilterState;
  dispatch: Dispatch<TalentDiscoveryFilterAction>;
}>({
  state: initialTalentDiscoveryFilterState,
  dispatch: () => undefined,
});

export const TalentDiscoveryFilterProvider = ({
  children,
}: PropsWithChildren) => {
  const [state, dispatch] = useReducer(
    talentDiscoveryFilterReducer,
    initialTalentDiscoveryFilterState
  );

  return (
    <TalentDiscoveryFilterContext.Provider value={{ state, dispatch }}>
      {children}
    </TalentDiscoveryFilterContext.Provider>
  );
};

export const useTalentDiscoveryFilter = () =>
  useContext(TalentDiscoveryFilterContext);
