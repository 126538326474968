import { SelectionListValue } from '../../../selection-list';
import { useSavedSetsSelectOptions } from './saved-sets-selection-list.context';
import { useNodeModalState } from '../../node-modal-state.context';
import NodeEditModal from '../../../tree/node-edit-modal/node-edit-modal';
import { get } from 'lodash';
import { API_ROOT } from '@revelio/auth';
import { filterStore, getActiveTabMeta } from '../../../../engine/filters.core';
import { useMemo } from 'react';
import { SavedSetOption } from '../../../selection-list/saved-set-option';
import { useGetStoredSets } from '../../../../engine/filters.savedset';
import { SavedSetsNestedSelectionList } from './saved-sets-nested-selection-list';
// import { View } from '@revelio/data-access';

// const ScreenerViews = [
//   View.CompanyScreener,
//   View.GeographyScreener,
//   View.RoleScreener,
// ];

export const SavedSetsSelectionList = ({ close }: { close: () => void }) => {
  const { options } = useGetStoredSets();

  const { selectedOption, clearSelectedOption } = useSavedSetsSelectOptions();

  const { nodeModalOpen, setNodeModalOpen } = useNodeModalState();

  const tabMeta = filterStore.query(getActiveTabMeta);

  const selectOptions = useMemo(() => {
    return options
      .map((option) => ({
        value: option.id,
        label: option.name,
        entity: option,
        isDisabled: option.view !== tabMeta.savedSetView,
      }))
      .sort((a, b) => {
        if (a.isDisabled === b.isDisabled) {
          return a.value.localeCompare(b.value);
        }
        return a.isDisabled ? 1 : -1;
      });
  }, [options, tabMeta.savedSetView]);

  const submitSavedSetSelection = (value: SelectionListValue) => {
    const deeplink = get(value, 'entity.filters');

    const url = new URL(`${API_ROOT}${deeplink}`);
    const searchParams = url.searchParams;

    if (searchParams) {
      window.location.search = searchParams.toString();
    }

    close();
  };

  return (
    <>
      <NodeEditModal
        node={selectedOption}
        isOpen={nodeModalOpen}
        onClose={function (): void {
          clearSelectedOption();
          setNodeModalOpen(false);
        }}
      />
      <SavedSetsNestedSelectionList
        options={selectOptions}
        close={close}
        submit={submitSavedSetSelection}
        isMulti={false}
        OptionComponent={SavedSetOption}
        onChangeOverride={(selectedOptions, { setValue }) => {
          setValue((prev) => {
            if (get(prev, 'value') === get(selectedOptions, 'value')) {
              return [];
            }

            return selectedOptions;
          });
        }}
      />
    </>
  );
};
