import { GetTalentDiscoveryMapStatsQueryVariables } from '../../gql/graphql';

export const TALENT_DISCOVERY_FIELDS = [
  'company',
  'country',
  'msa',
  'gender',
  'ethnicity',
  'seniority',
  'highest_degree',
  'flight_risk',
  'prestige',
  'remote_suitability',
  'job_category',
  'role_k150',
  'role_k1500',
] as const;
export type TalentDiscoveryField = (typeof TALENT_DISCOVERY_FIELDS)[number];

export type GetTalentDiscoveryTooltipPayload =
  GetTalentDiscoveryMapStatsQueryVariables;

export interface TalentDiscoveryTooltipData {
  growth: string | number;
  marketTightness: string | number;
  postings: number;
  salaryDist: number[];
  timeToFill: string | number;
  headcount: number;
}

export type TalentDiscoveryMutateResponse =
  | { success: true; message: string }
  | { success: false; error: string };
