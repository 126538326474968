import { CreatableSelect } from 'chakra-react-select';
import { isUndefined } from 'lodash';
import { useState } from 'react';

/* eslint-disable-next-line */
export interface FreeTextProps {
  placeholder: string;
  value: any;
  isMulti?: boolean;
  inputLengthLimit?: number;
  formatFn?: (input: string) => string;
  onChange: (e: Event) => void;
  noOptionsMessage: () => string;
  formatCreateLabel: (inputText: string) => string;
}

export function FreeText({
  placeholder,
  value,
  inputLengthLimit,
  formatFn = (input) => input,
  isMulti = true,
  onChange,
  noOptionsMessage,
  formatCreateLabel,
}: FreeTextProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <CreatableSelect
      placeholder={placeholder}
      size="sm"
      value={value}
      isMulti={isMulti}
      options={[]}
      onKeyDown={(e: any) => {
        if (e.key === 'Enter' && dropdownOpen) {
          e.stopPropagation();
        }
      }}
      onChange={onChange}
      onInputChange={(e) => {
        const formatted = formatFn(e);

        if (!isUndefined(inputLengthLimit) && e.length > inputLengthLimit) {
          return formatted.slice(0, inputLengthLimit);
        }

        return formatted;
      }}
      onMenuClose={() => {
        setDropdownOpen(false);
      }}
      onMenuOpen={() => {
        setDropdownOpen(true);
      }}
      noOptionsMessage={noOptionsMessage}
      formatCreateLabel={formatCreateLabel}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
    />
  );
}

export default FreeText;
