import { Flex } from '@chakra-ui/layout';
import { useMemo, useRef, useEffect } from 'react';
import {
  ScreenerFilterOption,
  isCompanyDetailTreeFilter,
  isCompanyDetailCheckboxFilter,
  isCompanyDetailYearRangeFilter,
  isCompanySearchFilter,
  isSchoolSearchFilter,
  isFreeTextFilter,
  isCompanyDetailRangeFilter,
} from '../types';
import { TreeFilter } from './tree-filter';
import { CheckboxFilter } from './checkbox-filter';
import { YearRangeFilter } from './year-range-filter';
import { CompanySearchFilter } from './company-search-filter';
import { SchoolSearchFilter } from './school-search-filter';
import { FreeTextFilter } from './free-text-filter';
import { RangeFilter } from './range-filter';

export const CompanyDetailFilter = ({
  selectedFilter,
  closeMenu,
}: {
  selectedFilter: ScreenerFilterOption;
  closeMenu: () => void;
}) => {
  const isTreeFilter = isCompanyDetailTreeFilter(selectedFilter?.value);
  const isCheckboxFilter = isCompanyDetailCheckboxFilter(selectedFilter?.value);
  const isYearRangeFilter = isCompanyDetailYearRangeFilter(
    selectedFilter?.value
  );
  const isCompanySearch = isCompanySearchFilter(selectedFilter?.value);
  const isSchoolSearch = isSchoolSearchFilter(selectedFilter?.value);
  const isFreeTextSearchFilter = isFreeTextFilter(selectedFilter?.value);
  const isRangeFilter = isCompanyDetailRangeFilter(selectedFilter?.value);

  const renderFilterComponent = useMemo(() => {
    if (isCompanySearch) {
      return (
        <CompanySearchFilter
          treeHeight={220}
          closeMenu={closeMenu}
          selectedFilter={selectedFilter}
        />
      );
    }

    if (isTreeFilter) {
      return (
        <TreeFilter
          selectionLists={selectedFilter.filterGranularities || []}
          closeMenu={closeMenu}
          selectedFilter={selectedFilter}
        />
      );
    }

    if (isCheckboxFilter) {
      return (
        <CheckboxFilter
          selectOptions={selectedFilter.checkboxOptions || []}
          closeMenu={closeMenu}
          selectedFilter={selectedFilter}
        />
      );
    }

    if (isYearRangeFilter) {
      return (
        <YearRangeFilter
          closeMenu={closeMenu}
          selectedFilter={selectedFilter}
        />
      );
    }

    if (isSchoolSearch) {
      return (
        <SchoolSearchFilter
          treeHeight={220}
          closeMenu={closeMenu}
          selectedFilter={selectedFilter}
        />
      );
    }

    if (isFreeTextSearchFilter) {
      return (
        <FreeTextFilter closeMenu={closeMenu} selectedFilter={selectedFilter} />
      );
    }

    if (isRangeFilter) {
      return (
        <RangeFilter closeMenu={closeMenu} selectedFilter={selectedFilter} />
      );
    }
    return null;
  }, [
    isCompanySearch,
    isTreeFilter,
    isCheckboxFilter,
    isYearRangeFilter,
    isSchoolSearch,
    isFreeTextSearchFilter,
    isRangeFilter,
    closeMenu,
    selectedFilter,
  ]);

  // Handle click outside to close
  const componentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest('.chakra-popover__popper')
      ) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeMenu]);

  return (
    <Flex direction="column" gap="4" ref={componentRef}>
      {renderFilterComponent}
    </Flex>
  );
};
