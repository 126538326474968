import { Box, Flex, Text } from '@chakra-ui/layout';
import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import Select, { components, OptionProps } from 'react-select';
import {
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { SearchIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { CustomOption } from '../../../selection-list/option';
import { defaultStyles } from '../../../selection-list';

const getEnabledAndDisabledOptions = (options: Option[] | undefined) => {
  return {
    enabledOptions: options?.filter((option) => !option.isDisabled),
    disabledOptions: options?.filter((option) => option.isDisabled),
  };
};

export type Option = {
  entity?: any;
  value: string;
  label: string;
  isDisabled?: boolean;
  disabled?: boolean;
};

export type SelectionListValue = readonly Option[];
export type SelectionListControls = {
  value: SelectionListValue;
  setValue: React.Dispatch<React.SetStateAction<SelectionListValue>>;
  clearSelections: () => void;
};

export type SavedSetsNestedSelectionListProps = {
  defaultValue?: Option[];
  close?: () => void;
  onChangeOverride?: (
    selectedOptions: SelectionListValue,
    { value, setValue, clearSelections }: SelectionListControls
  ) => void;
  submitLabel?: string;
  submit: (value: SelectionListValue) => void;
  isMulti?: boolean;
  OptionComponent?: React.FC<OptionProps<Option, true>>;
  options: Option[];
};

export const SavedSetsNestedSelectionList = ({
  options,
  defaultValue = [],
  close,
  onChangeOverride,
  submitLabel = 'Add',
  submit,
  isMulti = true,
  OptionComponent,
}: SavedSetsNestedSelectionListProps) => {
  const [value, setValue] = useState<SelectionListValue>(defaultValue);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDisabled, setShowDisabled] = useState(false);

  const prevDefaultValue = useRef(defaultValue);
  useEffect(() => {
    if (!isEqual(prevDefaultValue.current, defaultValue)) {
      prevDefaultValue.current = defaultValue;
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const clearSelections = useCallback(() => {
    setValue([]);
  }, [setValue]);

  const handleAddSelections = () => {
    submit(value);
    close && close();
  };

  const onChange = useCallback(
    (selectedOptions: SelectionListValue) => {
      if (onChangeOverride) {
        onChangeOverride(selectedOptions, { value, setValue, clearSelections });
      } else {
        setValue(selectedOptions);
      }
    },
    [onChangeOverride, value, setValue, clearSelections]
  );

  // Step 1: Filter options based on the search term
  const filteredOptions = useMemo(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    return options.filter((option) =>
      option.label.toLowerCase().includes(lowercasedSearchTerm)
    );
  }, [options, searchTerm]);

  const { enabledOptions, disabledOptions } = useMemo(
    () => getEnabledAndDisabledOptions(filteredOptions),
    [filteredOptions]
  );
  const showOtherPagesFilterSets = (disabledOptions?.length ?? 0) > 0;

  const SelectComponent = useMemo(
    () =>
      ({
        options,
        maxMenuHeight,
      }: {
        options: Option[] | undefined;
        maxMenuHeight: number;
      }) => (
        <Select<Option, true>
          options={options}
          value={value}
          isSearchable={false}
          menuIsOpen
          maxMenuHeight={maxMenuHeight}
          placeholder="Search..."
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          isClearable={false}
          backspaceRemovesValue={false}
          components={{
            Control: () => null,
            DropdownIndicator: (props) => (
              <components.DropdownIndicator {...props}>
                <SearchIcon />
              </components.DropdownIndicator>
            ),
            Option: (props: OptionProps<Option, true>) =>
              OptionComponent ? (
                <OptionComponent {...props} />
              ) : (
                <CustomOption {...props} />
              ),
          }}
          onChange={onChange}
          {...(isMulti ? { isMulti: true } : {})}
          styles={{
            container: (base) => ({ ...base, ...defaultStyles.container }),
            control: (base) => ({ ...base, ...defaultStyles.control }),
            valueContainer: (base) => ({
              ...base,
              ...defaultStyles.valueContainer,
            }),
            input: (base) => ({ ...base, ...defaultStyles.input }),
            indicatorsContainer: (base) => ({
              ...base,
              ...defaultStyles.indicatorsContainer,
            }),
            indicatorSeparator: (base) => ({
              ...base,
              ...defaultStyles.indicatorSeparator,
            }),
            dropdownIndicator: (base) => ({
              ...base,
              ...defaultStyles.dropdownIndicator,
            }),
            menu: (base) => ({ ...base, ...defaultStyles.menu }),
            menuList: (base) => ({
              ...base,
              ...defaultStyles.menuList,
            }),
            option: (base, { isFocused, data }) => {
              return {
                ...base,
                ...defaultStyles.option,
                backgroundColor: isFocused ? '#F7FAFC' : 'transparent',
                borderColor: isFocused ? '#CBD5E0' : '#E2E8F0',
              };
            },
          }}
        />
      ),
    [OptionComponent, isMulti, onChange, value]
  );

  const toggleDisabled = () => {
    setShowDisabled((prev) => !prev);
  };

  return (
    <Box>
      <InputGroup mb={4} size="sm">
        <Input
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <InputRightElement pointerEvents="none">
          <SearchIcon color="gray.400" />
        </InputRightElement>
      </InputGroup>
      {(enabledOptions?.length || 0) > 0 ? (
        <SelectComponent options={enabledOptions} maxMenuHeight={215} />
      ) : (
        <Text color="gray.500" mb={2}>
          No compatible sets found.
        </Text>
      )}
      {showOtherPagesFilterSets && (
        <Box mb={4}>
          <Flex
            color="navyBlue.500"
            align="center"
            cursor="pointer"
            onClick={toggleDisabled}
            mb={2}
          >
            <Box mr={1}>
              <ChevronDownIcon
                fontSize={24}
                transform={showDisabled ? undefined : 'rotate(-90deg)'}
              />
            </Box>
            <Text fontSize={12}>Filter Sets from other pages</Text>
          </Flex>
          <Box ml={6}>
            {showDisabled && (
              <SelectComponent options={disabledOptions} maxMenuHeight={120} />
            )}
          </Box>
        </Box>
      )}
      <Flex justifyContent="space-between">
        <Button
          size="sm"
          fontSize="12px"
          variant="ghost"
          colorScheme="red"
          onClick={clearSelections}
        >
          Clear Selections
        </Button>
        <ButtonGroup size="sm" spacing={4}>
          <Button
            size="md"
            fontSize="12px"
            variant="link"
            colorScheme="gray"
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            variant="filteraction"
            bg="green.500"
            colorScheme="green"
            onClick={handleAddSelections}
            data-testid="filter-popover-submit"
            isDisabled={value?.length === 0}
          >
            {submitLabel}
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
};
