import { propsFactory, createStore } from '@ngneat/elf';

export const {
  withIsTour,
  updateIsTour,
  selectIsTour,
  resetIsTour,
  getIsTour,
  setIsTour,
} = propsFactory('isTour', {
  initialValue: false,
});

export const {
  withHasTouredCompositions,
  updateHasTouredCompositions,
  selectHasTouredCompositions,
  resetHasTouredCompositions,
  getHasTouredCompositions,
  setHasTouredCompositions,
} = propsFactory('hasTouredCompositions', {
  initialValue: false,
});

export const {
  withIsSideBarOpen,
  updateIsSideBarOpen,
  selectIsSideBarOpen,
  resetIsSideBarOpen,
  getIsSideBarOpen,
  setIsSideBarOpen,
} = propsFactory('isSideBarOpen', {
  initialValue: false,
});

export const {
  withIsSideBarCompany,
  updateIsSideBarCompany,
  selectIsSideBarCompany,
  resetIsSideBarCompany,
  getIsSideBarCompany,
  setIsSideBarCompany,
} = propsFactory('isSideBarCompany', {
  initialValue: true,
});

export const {
  withIsControlPanelSpotlighted,
  updateIsControlPanelSpotlighted,
  selectIsControlPanelSpotlighted,
  resetIsControlPanelSpotlighted,
  getIsControlPanelSpotlighted,
  setIsControlPanelSpotlighted,
} = propsFactory('isControlPanelSpotlighted', {
  initialValue: false,
});

export const {
  withStepIndex,
  updateStepIndex,
  selectStepIndex,
  resetStepIndex,
  getStepIndex,
  setStepIndex,
} = propsFactory('stepIndex', {
  initialValue: 0,
});

export const {
  withIsTourComplete,
  updateIsTourComplete,
  selectIsTourComplete,
  resetIsTourComplete,
  getIsTourComplete,
  setIsTourComplete,
} = propsFactory('isTourComplete', {
  initialValue: false,
});

export const tourStore = createStore(
  {
    name: 'tour',
  },
  withIsTour(),
  withIsSideBarOpen(),
  withIsSideBarCompany(),
  withIsControlPanelSpotlighted(),
  withStepIndex(),
  withIsTourComplete(),
  withHasTouredCompositions()
);
