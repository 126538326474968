import React, { createContext, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverHeaderProps,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { LazyBehavior } from '@chakra-ui/utils';
import {
  PopoverTrigger,
  UserTrackingEvents,
  emitMixpanelUserEvent,
} from '@revelio/core';
import {
  ACTION_MENU_LIST_CLASS_NAME,
  SUBMENU_CONTENT_CLASS_NAME,
} from '../utils/constants';

export const FilterPopoverContext = createContext<boolean | undefined>(
  undefined
);

/* eslint-disable-next-line */
export interface FilterPopoverProps {
  title?: string;
  showHeader?: boolean;
  resetBtn?: React.ReactNode;
  triggerElement: React.ReactNode;
  lazy?: boolean;
  defaultIsOpen?: boolean;
  closeOnBlur?: boolean;
  menuPlacement?: PlacementWithLogical | undefined;
  children?: React.ReactNode;
  cancelButton?: React.ReactNode;
  submitButton?: React.ReactNode;
  arrow?: boolean;
  menuWidth?: number;
  handleSubmit?: any;
  submitFunction?: () => void;
  openHandler?: () => void;
  triggerOnClose?: () => void;
  submitButtonText?: string;
  isSubmitDisabled?: boolean;
  selectMenuOpenDefault?: boolean;
  setHasClosed?: any;
  initialFocusRef?: any;
  fpPaddingTop?: number;
  fpPaddingBottom?: number;
  lazyBehavior?: LazyBehavior;
  submitOnEnter?: boolean;
  submitIsLoading?: boolean;
  submitTestId?: string;
  nodeModalOpen?: boolean;
  closeOnOutsideClick?: boolean;
  closeOnSubmit?: boolean;
  showActionMenu?: boolean;
  externalControl?: {
    isOpen?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
  };
  testId?: string;
  id?: string;
  isDisabled?: boolean;
  hideDepracatedCtas?: boolean;
  headerProps?: PopoverHeaderProps;
}

export function FilterPopover({
  title,
  resetBtn,
  triggerElement,
  lazy,
  closeOnBlur,
  menuPlacement,
  children,
  arrow,
  handleSubmit,
  submitFunction,
  submitButtonText,
  openHandler,
  triggerOnClose,
  isSubmitDisabled,
  menuWidth = 400,
  selectMenuOpenDefault,
  setHasClosed,
  initialFocusRef,
  defaultIsOpen = false,
  fpPaddingTop = 3,
  fpPaddingBottom,
  showHeader = false,
  lazyBehavior = 'unmount',
  submitOnEnter = true,
  submitIsLoading,
  submitTestId,
  nodeModalOpen,
  showActionMenu,
  closeOnOutsideClick = false,
  closeOnSubmit = true,
  externalControl = {},
  testId,
  id,
  isDisabled = false,
  hideDepracatedCtas,
  headerProps = {},
}: FilterPopoverProps) {
  const { isOpen, onOpen, onClose } = useDisclosure(externalControl);

  const onCloseTriggered = () => {
    onClose();
    if (typeof triggerOnClose === 'function') {
      triggerOnClose();
    }
  };

  const onSubmitHandler = () => {
    if (typeof handleSubmit === 'function') {
      handleSubmit();
    }

    if (closeOnSubmit) {
      onCloseTriggered();
    }
  };

  const handleTriggerClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    onOpen();
  };

  const onEnterSubmitHandler = (e: any) => {
    const isEnterPressed = e.key === 'Enter';

    if (nodeModalOpen) {
      return;
    }

    if (isEnterPressed) {
      const isKeyHeld = e.repeat;

      if (isKeyHeld) {
        return;
      }

      const shouldTriggerSubmit = isOpen && submitOnEnter && !isSubmitDisabled;

      if (shouldTriggerSubmit) {
        submitFunction?.() ?? onSubmitHandler();
      }
    }
  };

  const container = useRef<any>(null);

  useOutsideClick({
    enabled: isOpen && !closeOnBlur && closeOnOutsideClick,
    ref: container,
    handler: (e: any) => {
      if (nodeModalOpen) {
        return;
      }

      // TODO: Might need a polyfill for closest for legacy support
      const clickInActionMenu =
        showActionMenu && e.target.closest(`.${ACTION_MENU_LIST_CLASS_NAME}`);

      const clickInSubmenu = e.target.closest(`.${SUBMENU_CONTENT_CLASS_NAME}`);

      if (clickInSubmenu || clickInActionMenu) {
        return;
      }

      onCloseTriggered();
    },
  });

  useEffect(() => {
    if (defaultIsOpen) {
      onOpen();
    }
  }, [defaultIsOpen, onOpen]);

  return (
    <Box
      onKeyDown={onEnterSubmitHandler}
      ref={container}
      data-testid="filter-popover"
    >
      <Popover
        isLazy={lazy}
        placement={menuPlacement || 'bottom-start'}
        closeOnBlur={closeOnBlur}
        onOpen={function () {
          if (typeof openHandler === 'function') {
            openHandler();
          }

          emitMixpanelUserEvent(UserTrackingEvents.OPEN_MENU);
        }}
        onClose={() => {
          onCloseTriggered();
          if (selectMenuOpenDefault) {
            setHasClosed(false);
          }
        }}
        isOpen={isOpen}
        initialFocusRef={initialFocusRef}
        lazyBehavior={lazyBehavior}
        defaultIsOpen={defaultIsOpen}
      >
        <PopoverTrigger>
          <Box onClick={(e) => !isDisabled && handleTriggerClick(e)}>
            {triggerElement}
          </Box>
        </PopoverTrigger>
        <PopoverContent
          pointerEvents={isOpen ? 'auto' : 'none'}
          minWidth={`${menuWidth}px`}
          width="auto"
          display="flex"
          flexDirection="column"
          alignItems="space-evenly"
          px={3}
          pb={3}
          boxShadow="2xl"
          // workaround to fix focus shadow bug
          // ensures popover has box shadow when open
          sx={{
            ':focus:not(:focus-visible)': {
              shadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
            },
          }}
        >
          {arrow && <PopoverArrow />}
          {showHeader && (
            <PopoverHeader px="0" fontWeight="semibold" {...headerProps}>
              {title}
            </PopoverHeader>
          )}
          <PopoverBody
            paddingInlineStart={0}
            paddingInlineEnd={0}
            paddingTop={fpPaddingTop}
            paddingBottom={0}
          >
            <FilterPopoverContext.Provider value={isOpen}>
              <div id={id} data-testid={testId}>
                {children}
              </div>
            </FilterPopoverContext.Provider>
          </PopoverBody>
          {hideDepracatedCtas ? null : (
            <Flex justifyContent="space-between" alignItems="center" pt={3}>
              {resetBtn}
              <ButtonGroup
                size="sm"
                height="100%"
                justifyContent="flex-end"
                w="100%"
                spacing={4}
              >
                <Button
                  variant="link"
                  fontSize="12px"
                  colorScheme="gray"
                  onClick={() => {
                    onCloseTriggered();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  bg="green.500"
                  variant="filteraction"
                  isDisabled={isSubmitDisabled}
                  onClick={submitFunction || onSubmitHandler}
                  isLoading={submitIsLoading}
                  data-testid={submitTestId ?? 'filter-popover-submit'}
                >
                  {submitButtonText || 'Update'}
                </Button>
              </ButtonGroup>
            </Flex>
          )}
        </PopoverContent>
      </Popover>
    </Box>
  );
}

export default FilterPopover;
