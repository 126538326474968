import {
  SelectionCategories,
  LocalSelectionCategories,
  GEOGRAPHY_GRANULARITY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
} from '@revelio/filtering';
import { TalentDiscoveryFilterOption } from './types';

export const FILTER_OPTIONS: TalentDiscoveryFilterOption[] = [
  {
    label: 'Company',
    value: SelectionCategories.COMPANY,
    supportsCurrentOrPrevious: true,
  },
  {
    label: 'Education',
    value: SelectionCategories.HIGHEST_DEGREE,
    filterGranularities: [SelectionCategories.HIGHEST_DEGREE],
  },
  {
    label: 'Ethnicity',
    value: SelectionCategories.ETHNICITY,
    filterGranularities: [SelectionCategories.ETHNICITY],
  },
  {
    label: 'Flight Risk',
    value: LocalSelectionCategories.FLIGHT_RISK,
    filterGranularities: [LocalSelectionCategories.FLIGHT_RISK],
    supportsCurrentOrPrevious: true,
  },
  {
    label: 'Gender',
    value: SelectionCategories.GENDER,
    filterGranularities: [SelectionCategories.GENDER],
  },
  {
    label: 'Geography',
    value: 'geography',
    filterGranularities: GEOGRAPHY_GRANULARITY_FILTERS,
    supportsCurrentOrPrevious: true,
  },
  {
    label: 'Name',
    value: SelectionCategories.NAME,
    placeholder: 'Search for a name',
  },
  {
    label: 'Prestige',
    value: LocalSelectionCategories.PRESTIGE,
    filterGranularities: [LocalSelectionCategories.PRESTIGE],
    supportsCurrentOrPrevious: true,
  },
  {
    label: 'Remote Suitability',
    value: LocalSelectionCategories.REMOTE_SUITABILITY,
    filterGranularities: [LocalSelectionCategories.REMOTE_SUITABILITY],
    supportsCurrentOrPrevious: true,
  },
  {
    label: 'Role',
    value: 'role',
    filterGranularities: ROLE_GRANULARITY_FILTERS,
    supportsCurrentOrPrevious: true,
  },
  {
    label: 'School',
    value: 'rsid',
  },
  {
    label: 'Seniority',
    value: SelectionCategories.SENIORITY,
    filterGranularities: [SelectionCategories.SENIORITY],
    supportsCurrentOrPrevious: true,
  },
  {
    label: 'Pay',
    value: 'salary_total',
    supportsCurrentOrPrevious: true,
    prefix: '$',
  },
  {
    label: 'Skill',
    value: SelectionCategories.SKILL,
    filterGranularities: SKILL_GRANULARITY_FILTERS,
    supportsMultiple: true,
  },
  {
    label: 'Keywords',
    value: SelectionCategories.KEYWORD,
    supportsMultiple: true,
    placeholder: 'e.g. linux, hardware, firewall, LAN...',
  },
];
