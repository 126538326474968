import {
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Select, SingleValue } from 'chakra-react-select';
import NumberFormat, {
  NumberFormatPropsBase,
  NumberFormatValues,
} from 'react-number-format';
import {
  OperatorOption,
  ScreenerFilterOption,
  isRangeFilterState,
} from '../types';
import { Input } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useScreenerFilter } from '../screener-filter-provider';
import { SelectFooter } from '../select-footer';

interface RangeFilterProps {
  closeMenu: () => void;
  selectedFilter: ScreenerFilterOption;
}

export const operatorOptions: OperatorOption[] = [
  { label: 'is between', value: 'between' },
  { label: 'is greater than', value: 'greater_than' },
  { label: 'is less than', value: 'less_than' },
];

const NumberInput = ({
  ...restProps
}: NumberFormatPropsBase<typeof Input> & { onBlur: () => void }) => {
  return (
    <NumberFormat
      {...restProps}
      customInput={Input}
      displayType="input"
      height="36px"
      fontSize="14px"
      padding="10px"
      borderRadius="2px"
      allowNegative={false}
      onBlur={restProps.onBlur}
      thousandSeparator=","
    />
  );
};

export const RangeFilter = ({
  closeMenu,
  selectedFilter,
}: RangeFilterProps) => {
  const { state, dispatch } = useScreenerFilter();
  const [operator, setOperator] = useState<OperatorOption>(operatorOptions[0]);
  const [minValue, setMinValue] = useState<number | undefined>(undefined);
  const [maxValue, setMaxValue] = useState<number | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [minValueTouched, setMinValueTouched] = useState(false);
  const [maxValueTouched, setMaxValueTouched] = useState(false);

  const selectedRangeState = state.filters.company_detail_filters?.find(
    (filter) => filter.name === selectedFilter.value
  );

  useEffect(() => {
    setOperator(operatorOptions[0]);
    setMinValue(undefined);
    setMaxValue(undefined);
    setErrorMessages([]);
    setMinValueTouched(false);
    setMaxValueTouched(false);
  }, [selectedFilter]);

  useEffect(() => {
    if (isRangeFilterState(selectedRangeState)) {
      const { start_val: min, end_val: max } = selectedRangeState;
      setMinValue(min);
      setMaxValue(max);

      const operatorValue = (() => {
        if (min !== undefined && max !== undefined) {
          return 'between';
        }
        if (min !== undefined) {
          return 'greater_than';
        }
        return 'less_than';
      })();
      const selectedOperator =
        operatorOptions.find((opt) => opt.value === operatorValue) ||
        operatorOptions[0];
      setOperator(selectedOperator);
    }
  }, [selectedRangeState]);

  const handleOperatorChange = (value: SingleValue<OperatorOption>) => {
    setOperator(value || operatorOptions[0]);
    setMinValue(undefined);
    setMaxValue(undefined);
    setErrorMessages([]);
    setMinValueTouched(false);
    setMaxValueTouched(false);
  };

  const handleMinValueChange = (values: NumberFormatValues) => {
    setMinValue(values.floatValue);
  };

  const handleMaxValueChange = (values: NumberFormatValues) => {
    setMaxValue(values.floatValue);
  };

  useEffect(() => {
    const errors: string[] = [];

    if (minValueTouched) {
      if (minValue !== undefined && minValue < 0) {
        errors.push('Minimum value must be a positive number.');
      }
    }

    if (maxValueTouched) {
      if (maxValue !== undefined && maxValue < 0) {
        errors.push('Maximum value must be a positive number.');
      }
    }

    if (
      operator?.value === 'between' &&
      minValueTouched &&
      maxValueTouched &&
      minValue !== undefined &&
      maxValue !== undefined &&
      minValue > maxValue
    ) {
      errors.push(
        'Minimum value must be less than or equal to the maximum value.'
      );
    }

    setErrorMessages(errors);
  }, [operator, minValue, maxValue, minValueTouched, maxValueTouched]);

  const isInvalid =
    errorMessages.length > 0 && (minValueTouched || maxValueTouched);

  const isDisabled = (() => {
    if (operator?.value === 'greater_than' && minValue === undefined) {
      return true;
    }

    if (operator?.value === 'less_than' && maxValue === undefined) {
      return true;
    }

    if (
      operator?.value === 'between' &&
      (minValue === undefined || maxValue === undefined)
    ) {
      return true;
    }

    return isInvalid;
  })();

  const handleAddFilter = () => {
    if (!isInvalid) {
      dispatch({
        type: 'ADD_RANGE_FILTER',
        name: selectedFilter.value as string,
        min: minValue,
        max: maxValue,
      });
      closeMenu();
    }
  };

  const handleClearSelections = () => {
    setMinValue(undefined);
    setMaxValue(undefined);
    setOperator(operatorOptions[0]);
    setErrorMessages([]);
    setMinValueTouched(false);
    setMaxValueTouched(false);
  };

  const prefix = selectedFilter.prefix || '';

  return (
    <>
      <Flex direction="column" gap="3" fontSize="14px" mb={2}>
        <Select
          options={operatorOptions}
          value={operator}
          onChange={handleOperatorChange}
          size="sm"
          selectedOptionColorScheme="green"
          chakraStyles={{
            control: (provider) => ({
              ...provider,
              height: '36px',
            }),
          }}
        />
        <FormControl isInvalid={isInvalid}>
          {operator?.value === 'between' ? (
            <Flex
              alignItems="center"
              gap="2.5"
              fontSize="14px"
              justifyContent="space-between"
            >
              <NumberInput
                value={minValue}
                onValueChange={handleMinValueChange}
                onBlur={() => setMinValueTouched(true)}
                data-testid="min-value-input"
                prefix={prefix}
              />
              <Text>to</Text>
              <NumberInput
                value={maxValue}
                onValueChange={handleMaxValueChange}
                onBlur={() => setMaxValueTouched(true)}
                data-testid="max-value-input"
                prefix={prefix}
              />
            </Flex>
          ) : (
            <NumberInput
              key={`single-${operator.value}`}
              value={operator?.value === 'greater_than' ? minValue : maxValue}
              onValueChange={
                operator?.value === 'greater_than'
                  ? handleMinValueChange
                  : handleMaxValueChange
              }
              onBlur={
                operator?.value === 'greater_than'
                  ? () => setMinValueTouched(true)
                  : () => setMaxValueTouched(true)
              }
              prefix={prefix}
            />
          )}
          <FormErrorMessage>
            <VStack align="start">
              {errorMessages.map((msg, idx) => (
                <Text key={idx} color="red.600">
                  {msg}
                </Text>
              ))}
            </VStack>
          </FormErrorMessage>
        </FormControl>
      </Flex>
      <SelectFooter
        onClearSelections={handleClearSelections}
        onClose={closeMenu}
        onAdd={handleAddFilter}
        addLabel={selectedRangeState ? 'Update' : 'Add'}
        isAddDisabled={isDisabled}
      />
    </>
  );
};
