import { GetTalentDiscoveryTooltipPayload } from '@revelio/data-access';
import { TalentDiscoveryFilterState } from '../filters/td-filter-reducer';
import { serialiseTalentDiscoveryFilters } from './serialiseTalentDiscoveryFilters';

export const serialiseTooltipFilters = async (
  filters: TalentDiscoveryFilterState['filters']
): Promise<GetTalentDiscoveryTooltipPayload> => {
  const initialSerialisedFilters = await serialiseTalentDiscoveryFilters(
    filters,
    true
  );

  return Object.entries(initialSerialisedFilters).reduce(
    (acc, [key, value]) => {
      if (
        [
          'company',
          'job_category',
          'role_k150',
          'role_k1500',
          'seniority',
        ].includes(key)
      ) {
        return { ...acc, [key]: (value as { ids: number[] })?.ids };
      }

      if (['ethnicity'].includes(key)) {
        return { ...acc, [key]: value };
      }

      return acc;
    },
    {} as GetTalentDiscoveryTooltipPayload
  );
};
