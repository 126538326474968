import { forwardRef, MutableRefObject, useState } from 'react';
import { get } from 'lodash';
import { filter, map, pipe } from 'rxjs';

import { PopoverBody, PopoverFooter } from '@chakra-ui/react';
import { SelectFooter } from '../select-footer';
import { useTalentDiscoveryFilter } from '../td-filter-provider';
import {
  TalentDiscoveryFilterOption,
  isCompanySearchFilterState,
  CompanySearchFilters,
} from '../types';
import {
  arrayToTree,
  CompanyResultItem,
  CompanySearchTree,
  SelectionCategories,
  ToggleSelect,
  TreeNode,
  useSelectionLists,
} from '@revelio/filtering';

export type CompanySearchFilterHandle = {
  handleClearSelections: () => void;
};

export interface CompanySearchFilterProps {
  treeHeight: number;
  searchInputRef?: MutableRefObject<HTMLInputElement | null>;
  closeMenu: () => void;
  selectedFilter: TalentDiscoveryFilterOption;
}

export const CompanySearchFilter = forwardRef<
  CompanySearchFilterHandle,
  CompanySearchFilterProps
>(
  (
    {
      treeHeight,
      searchInputRef,
      closeMenu,
      selectedFilter,
    }: CompanySearchFilterProps,
    ref
  ) => {
    const { state, dispatch } = useTalentDiscoveryFilter();
    const selectedCompanyDetailState = state.filters?.find(
      (filter) => filter.name === selectedFilter.value
    );

    const defaultSelectedItems = isCompanySearchFilterState(
      selectedCompanyDetailState
    )
      ? (selectedCompanyDetailState?.companyResultItems as Record<
          string,
          CompanyResultItem
        >)
      : {};
    const [placeholderList, setPlaceholderList] = useState<TreeNode[]>([]);

    const [selections, setSelections] =
      useState<Record<string, CompanyResultItem>>(defaultSelectedItems);

    useSelectionLists(
      [SelectionCategories.COMPANY, SelectionCategories.INDUSTRY],
      undefined,
      pipe(filter((lists) => lists.length > 0)),
      pipe(
        map((res) => {
          if (res.error) {
            return;
          }
          const { rootItems } = arrayToTree(...res);

          setPlaceholderList(rootItems as TreeNode[]);
        })
      )
    );

    const onClearSelections = () => {
      setSelections({});
    };

    const isStateCompanyFilter =
      selectedCompanyDetailState &&
      isCompanySearchFilterState(selectedCompanyDetailState);

    const [isFilterCurrent, setIsFilterCurrent] = useState(
      isStateCompanyFilter ? selectedCompanyDetailState?.isCurrent : undefined
    );

    const onChangeCurrent = (value: number) => {
      setIsFilterCurrent(!value);
    };

    const initialIsCurrent = (() => {
      if (isStateCompanyFilter) {
        return selectedCompanyDetailState?.isCurrent ?? true;
      }

      return true;
    })();

    const handleAddFilter = () => {
      if (Object.keys(selections).length) {
        dispatch({
          type: 'ADD_SEARCH_FILTER',
          name: selectedFilter.value as CompanySearchFilters,
          companyResultItems: selections,
          isCurrent: isFilterCurrent ?? true,
        });
      }

      closeMenu();
    };

    return (
      <>
        <PopoverBody padding={0}>
          <CompanySearchTree
            placeholderListNodes={placeholderList}
            selections={selections}
            setSelections={setSelections}
            sortFn={(a: TreeNode, b: TreeNode) => {
              const headcountA = get(a, 'item.raw_emp_count');
              const headcountB = get(b, 'item.raw_emp_count');
              return headcountB - headcountA;
            }}
            searchInputRef={searchInputRef}
            treeHeight={treeHeight}
            treeNodeType="checkboxes"
            inputGroupSize="sm"
          />
          {selectedFilter.supportsCurrentOrPrevious && (
            <ToggleSelect
              filterName={selectedFilter.label}
              onChange={onChangeCurrent}
              initialIsCurrent={initialIsCurrent}
            />
          )}
        </PopoverBody>
        <PopoverFooter padding="8px 0 0" borderTopWidth={0}>
          <SelectFooter
            onClearSelections={onClearSelections}
            onClose={closeMenu}
            onAdd={handleAddFilter}
            addLabel={
              !Object.keys(defaultSelectedItems).length ? 'Add' : 'Update'
            }
          />
        </PopoverFooter>
      </>
    );
  }
);
