import {
  TalentDiscoveryV1_5Filter,
  TalentDiscoveryV1_5Tags,
} from '@revelio/data-access';
import { SelectionCategories, TDFilterNameOverride } from '@revelio/filtering';
import { FILTER_OPTIONS } from '../filters/filter-configuration-options';
import { TalentDiscoveryFilterState } from '../filters/td-filter-reducer';
import {
  isTreeFilterState,
  isSchoolSearchFilterState,
  isFreeTextFilterState,
  isCompanySearchFilterState,
  isRangeFilterState,
} from '../filters/types';
import { fetchSubsidiaries } from '../../deliverables/company-selection/subsidiary-mapping/subsidiary-mapping.api';

type TalentDiscoveryRequestData =
  | {
      current: boolean;
      ids: number[];
      non_current: boolean;
    }
  | number[];

const skillLabels = {
  '75': TalentDiscoveryV1_5Tags.SkillsK75,
  '700': TalentDiscoveryV1_5Tags.SkillsK700,
  '3000': TalentDiscoveryV1_5Tags.SkillsK3000,
};

const fetchAllSubsidiaries = async (companyId: number) => {
  try {
    const subsidiaries = await fetchSubsidiaries(companyId, 'all-subsidiaries');
    const subsidiaryIds = subsidiaries.map((sub) => sub.rcid);
    // Include the parent company ID
    return [companyId, ...subsidiaryIds];
  } catch (error) {
    console.error(
      `Error fetching subsidiaries for company ID ${companyId}:`,
      error
    );
    // Return the parent company ID at least
    return [companyId];
  }
};

export const serialiseTalentDiscoveryFilters = async (
  filters: TalentDiscoveryFilterState['filters'],
  skipSubsidiaries = false
): Promise<TalentDiscoveryV1_5Filter> => {
  const parsed = await asyncReduce(
    filters || [],
    async (acc, filter) => {
      if (isTreeFilterState(filter)) {
        if (filter.name === SelectionCategories.SKILL) {
          const skillFilters = Object.values(filter.treeItems || {}).map(
            (item) => ({
              dim: skillLabels[
                item.selectionListId.split(
                  'skill_k'
                )[1] as keyof typeof skillLabels
              ] as TalentDiscoveryV1_5Tags,
              id: Number(item?.item?.id),
            })
          );

          return { ...acc, skills: [...(acc.skills || []), skillFilters] };
        }

        const items = Object.values(filter.treeItems || {});

        const innerAcc = await asyncReduce(
          items,
          async (innerAcc, item) => {
            const idKey =
              TDFilterNameOverride[item.selectionListId] ||
              item.selectionListId;

            const id = Number(item.item?.id);
            if (isNaN(id)) {
              return innerAcc;
            }

            const noCurrent = filter.isCurrent === undefined;

            if (noCurrent) {
              const existingValues = (innerAcc[idKey] as number[]) || [];
              const newValues = [...existingValues, id];
              return { ...innerAcc, [idKey]: newValues };
            } else {
              const existingData = (innerAcc[
                idKey
              ] as TalentDiscoveryRequestData) || {
                current: filter.isCurrent,
                non_current: !filter.isCurrent,
                ids: [],
              };

              const ids = Array.isArray(existingData)
                ? existingData
                : existingData.ids;

              const newData: TalentDiscoveryRequestData = {
                ...existingData,
                ids: [...ids, id],
              };
              return { ...innerAcc, [idKey]: newData };
            }
          },
          acc
        );

        return innerAcc;
      }

      if (isSchoolSearchFilterState(filter)) {
        const ids = Object.values(filter.schoolResultItems)
          .map((val) => val.rsid)
          .map(Number);
        return { ...acc, rsid: ids };
      }

      if (isFreeTextFilterState(filter)) {
        const key = filter.name === 'keywords' ? 'free_texts' : filter.name;

        if (
          FILTER_OPTIONS.find((option) => option.value === filter.name)
            ?.supportsMultiple
        ) {
          return { ...acc, [key]: [...(acc[key] || []), filter.text] };
        }

        return { ...acc, [key]: filter.text[0] };
      }

      if (isCompanySearchFilterState(filter)) {
        const ids = Object.keys(filter?.companyResultItems || {}).map(Number);

        const allSubsidiaryIdsArrays = skipSubsidiaries
          ? ids
          : await Promise.all(
              ids.map((companyId) => fetchAllSubsidiaries(companyId))
            );

        const allSubsidiaryIds = Array.from(
          new Set(allSubsidiaryIdsArrays.flat())
        );

        return {
          ...acc,
          company: {
            ids: allSubsidiaryIds,
            current: filter.isCurrent,
            non_current: !filter.isCurrent,
          },
        };
      }

      if (isRangeFilterState(filter)) {
        const current =
          filter.isCurrent !== undefined
            ? {
                current: filter.isCurrent,
                non_current: !filter.isCurrent,
              }
            : {};

        const start_value =
          filter.start_value === undefined ? 0 : filter.start_value;
        const end_value =
          filter.end_value === undefined ? 36445000 : filter.end_value;

        return {
          ...acc,
          [filter.name]: {
            start_value,
            end_value,
            ...current,
          },
        };
      }

      return acc;
    },
    {} as TalentDiscoveryV1_5Filter
  );

  return parsed || {};
};

async function asyncReduce<T, U>(
  array: T[],
  callback: (accumulator: U, currentValue: T, index: number) => Promise<U>,
  initialValue: U
): Promise<U> {
  let accumulator = initialValue;
  for (let index = 0; index < array.length; index++) {
    accumulator = await callback(accumulator, array[index], index);
  }
  return accumulator;
}
