import { Card, Flex } from '@chakra-ui/react';

import { TalentDiscoveryMap } from '@revelio/d3';
import {
  DefaultDates,
  FilterSets,
  LocalSelectionCategories,
  SelectionCategories,
  useDefaultLastMonth,
  useSelectionLists,
  useStoredFilterSet,
  useViewFilters,
  ViewTypes,
} from '@revelio/filtering';
import { Loading, PageTitles, Views } from '@revelio/core';

import TalentDiscoveryTable from './talent-discovery-table';
import { SplitPane } from './split-pane';
import { useTalentDiscoveryDataFetch } from './data-fetch';
import { serialiseTooltipFilters } from './data-fetch/serialiseTooltipFilters';
import { TalentDiscoveryFilterMenu } from './filters/talent-discovery-filter-menu';
import DashboardPage from '../DashboardPage';
import { TalentDiscoveryDownload } from './talent-discovery-download';
import {
  TalentDiscoveryFilterProvider,
  useTalentDiscoveryFilter,
} from './filters/td-filter-provider';
import { useEffect, useState } from 'react';
import { GetTalentDiscoveryTooltipPayload } from '@revelio/data-access';

const selectableFilters = [
  SelectionCategories.COMPANY,
  SelectionCategories.HIGHEST_DEGREE,
  SelectionCategories.ETHNICITY,
  SelectionCategories.GENDER,
  SelectionCategories.COUNTRY,
  SelectionCategories.METRO_AREA,
  SelectionCategories.REGION,
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K150,
  SelectionCategories.ROLE_K1500,
  SelectionCategories.SKILL_K75,
  SelectionCategories.SKILL_K700,
  SelectionCategories.SKILL_K3000,
  SelectionCategories.SENIORITY,
  SelectionCategories.KEYWORD,
  SelectionCategories.RSID,
  SelectionCategories.NAME,
  LocalSelectionCategories.PRESTIGE,
  LocalSelectionCategories.FLIGHT_RISK,
  LocalSelectionCategories.REMOTE_SUITABILITY,
  SelectionCategories.TOTAL_COMPENSATION,
  // SelectionCategories.BASE_SALARY,
];

const TalentDiscoveryContent = () => {
  const { data, loading } = useTalentDiscoveryDataFetch();
  const { state } = useTalentDiscoveryFilter();
  const [serialisedFilters, setSerialisedFilters] =
    useState<GetTalentDiscoveryTooltipPayload | null>(null);

  useStoredFilterSet({ sharedSetId: FilterSets.TALENT_DISCOVERY });
  useViewFilters(selectableFilters);
  useSelectionLists(selectableFilters);
  useDefaultLastMonth({
    view: Views.TALENT_DISCOVERY,
    viewType: ViewTypes.NONE,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
  });

  useEffect(() => {
    let isMounted = true;

    const fetchSerializedFilters = async () => {
      try {
        const result = await serialiseTooltipFilters(state.filters);
        if (isMounted) {
          setSerialisedFilters(result);
        }
      } catch (err) {
        if (isMounted) {
          console.error(err);
        }
      }
    };

    fetchSerializedFilters();

    // Cleanup function to prevent setting state on unmounted component
    return () => {
      isMounted = false;
    };
  }, [state.filters]);

  return (
    <DashboardPage
      title={[PageTitles.INDIVIDUAL, PageTitles.TALENT_DISCOVERY]}
      loading={loading}
      selections={
        <Flex width="full" justifyContent="space-between">
          <TalentDiscoveryFilterMenu />
          <TalentDiscoveryDownload />
        </Flex>
      }
    >
      {loading ? (
        <Card variant="unstyled" width="full" height="full" borderRadius="10px">
          <Loading />
        </Card>
      ) : (
        <SplitPane
          leftContent={
            <TalentDiscoveryTable
              apiData={data?.talent_discovery_search_v1_5}
            />
          }
          rightContent={
            <TalentDiscoveryMap
              data={{
                data: data?.talent_discovery_search_v1_5?.map_data || [],
                // get msa selections
                // filters: state?.filterSelections?.find((filterSelection) => filterSelection.name === 'msa')?.values,
              }}
              newFilterState={serialisedFilters}
            />
          }
        />
      )}
    </DashboardPage>
  );
};

export const TalentDiscovery = () => {
  return (
    <TalentDiscoveryFilterProvider>
      <TalentDiscoveryContent />
    </TalentDiscoveryFilterProvider>
  );
};
