import localForage from 'localforage';
import { emitAuthEvent, AuthEventIds, ACCOUNT_PORTAL_ROOT } from './auth';
import { oryLoginFlow, oryLogout } from './auth.ory';
import { clearAuthStore, updateLoggingOut } from './auth.repository';
import { userTracking } from '@revelio/core';
import { dashMetaLogout } from './auth.meta';

export const login = (values: { username: string; password: string }) => {
  return (
    Promise.resolve(values)
      // .then((response) => isResponseOk({ response }))
      .then(oryLoginFlow)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) => {
        // data.username = values.username;

        // make the user id be the new user id rather than old backend id
        data.legacy_id = data.id;
        data['id'] = data.ory.session.identity.id;

        emitAuthEvent({
          id: AuthEventIds.USER_LOGIN,
          data,
        });
        return { userId: data.username };
      })
  );
};

export const clearStorage = async () => {
  const clearFilterStore = localForage.dropInstance({
    name: 'rl-dashboard',
    storeName: 'filterStore',
  });
  const clearUserAuth = localForage.dropInstance({
    name: 'rl-dashboard',
    storeName: 'rl_user_auth',
  });
  sessionStorage.clear();
  localStorage.clear();

  return Promise.all([clearFilterStore, clearUserAuth]);
};

const logoutFallback = () => {
  clearStorage();
  updateLoggingOut(false);
  const searchParams = `?redirect_uri=${window.location.href}`;
  window.location.assign(`${ACCOUNT_PORTAL_ROOT}/login${searchParams}`);
};

export const fullLogout = async (explicitLogout = false) => {
  updateLoggingOut(true);
  userTracking.reset();

  const isLoggingOutInAdminPagesOrExplicitLogout =
    explicitLogout ||
    window.location.pathname.includes('/admin') ||
    window.location.pathname.includes('/manage');
  const searchParams = `?redirect_uri=${window.location.href}`;

  // if anything isn't caught or takes more than 6 seconds below then just try clearing things manually and push user to login
  const logoutTimeout = setTimeout(logoutFallback, 6000);

  try {
    // TODO: temp condition to allow old login page
    if (window.location.pathname != '/login') {
      const dashMetaClearStorage = dashMetaLogout();
      const manualClearStorage = clearStorage();
      // if the store isn't cleared, there's a race condition where it could be repopulated by app code after storage is cleared
      clearAuthStore();

      await Promise.all([dashMetaClearStorage, manualClearStorage]);

      await oryLogout();

      updateLoggingOut(false);
      window.location.assign(
        `${ACCOUNT_PORTAL_ROOT}/login${
          isLoggingOutInAdminPagesOrExplicitLogout ? '' : searchParams
        }`
      );
    }
  } catch (error) {
    clearTimeout(logoutTimeout);
    logoutFallback();
  }
};
