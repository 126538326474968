import { CompanyResultItem, SchoolItem, TreeItem } from '@revelio/filtering';
import {
  TreeFilters,
  CompanySearchFilters,
  FreeTextFilters,
  SchoolSearchFilters,
  isFreeTextFilterState,
  isTreeFilterState,
} from './types';

export type TalentDiscoveryTreeFilterState = {
  id: string;
  name: TreeFilters;
  treeItems?: Record<string, TreeItem>;
  isCurrent?: boolean;
};

export type TalentDiscoveryCompanySearchFilterState = {
  name: CompanySearchFilters;
  companyResultItems?: Record<string, CompanyResultItem>;
  isCurrent?: boolean;
};

export type TalentDiscoverySchoolSearchFilterState = {
  name: SchoolSearchFilters;
  schoolResultItems: Record<string, SchoolItem>;
};

export type TalentDiscoveryRangeFilterState = {
  name: string;
  start_value?: number;
  end_value?: number;
  isCurrent?: boolean;
};

export type TalentDiscoveryFreeTextState = {
  name: FreeTextFilters;
  text: string[];
  id: string;
};

export type TalentDiscoveryFilterStates =
  | TalentDiscoveryCompanySearchFilterState
  | TalentDiscoveryFreeTextState
  | TalentDiscoveryRangeFilterState
  | TalentDiscoverySchoolSearchFilterState
  | TalentDiscoveryTreeFilterState;

export enum TalentDiscoverySortCol {
  SORT_BY_ATTRITION = 'SORT_BY_ATTRITION',
  SORT_BY_GROWTHYOY = 'SORT_BY_GROWTH_YOY',
  SORT_BY_HEADCOUNT = 'SORT_BY_HEADCOUNT',
  SORT_BY_HIRING = 'SORT_BY_HIRING',
  SORT_BY_INFLOW = 'SORT_BY_INFLOW',
  SORT_BY_OUTFLOW = 'SORT_BY_OUTFLOW',
  SORT_BY_SALARY = 'SORT_BY_SALARY',
  SORT_BY_TENURE = 'SORT_BY_TENURE',
}

export type TalentDiscoverySorting = {
  sort_by: TalentDiscoverySortCol;
  num_col: number;
  ascending: boolean;
};

export interface TalentDiscoveryFilterState {
  page: number;
  filters?: TalentDiscoveryFilterStates[];
  sorting: TalentDiscoverySorting | undefined;
}

interface RemoveSubFilterAction {
  type: 'REMOVE_SUB_FILTER';
  filterId: string;
}

interface RemovePrimaryFilterAction {
  type: 'REMOVE_PRIMARY_FILTER';
}

interface UpdatePageAction {
  type: 'UPDATE_PAGE';
  page: number;
}

interface UpdateSortingAction {
  type: 'UPDATE_SORTING';
  sorting: TalentDiscoverySorting | undefined;
}

interface AddRangeFilter {
  type: 'ADD_RANGE_FILTER';
  name: string;
  start_value: number | undefined;
  end_value: number | undefined;
  isCurrent?: boolean;
}

interface RemoveRangeFilter {
  type: 'REMOVE_RANGE_FILTER';
  name: string;
}

interface AddTreeFilterAction {
  type: 'ADD_TREE_FILTER';
  name: TreeFilters;
  treeItems?: Record<string, TreeItem>;
  isCurrent?: boolean;
  id: string | null;
}

interface RemoveTreeFilterAction {
  type: 'REMOVE_TREE_FILTER';
  name: TreeFilters;
  id: string;
}

interface AddCompanySearchFilterAction {
  type: 'ADD_SEARCH_FILTER';
  name: CompanySearchFilters;
  companyResultItems: Record<string, CompanyResultItem>;
  isCurrent?: boolean;
}

interface RemoveCompanySearchFilterAction {
  type: 'REMOVE_SEARCH_FILTER';
  name: CompanySearchFilters;
}

interface AddSchoolDetailSearchFilterAction {
  type: 'ADD_SCHOOL_DETAIL_SEARCH_FILTER';
  name: SchoolSearchFilters;
  schoolResultItems: Record<string, SchoolItem>;
}

interface RemoveSchoolDetailSearchFilterAction {
  type: 'REMOVE_SCHOOL_DETAIL_SEARCH_FILTER';
  name: SchoolSearchFilters;
}

interface SetFiltersAction {
  type: 'OVERWRITE_FILTERS';
  filters: TalentDiscoveryFilterState['filters'];
}

interface AddFreeTextFiltersAction {
  type: 'ADD_FREE_TEXT_FILTER';
  name: FreeTextFilters;
  text: string[];
  id: string | null;
}

interface RemoveFreeTextFilterAction {
  type: 'REMOVE_FREE_TEXT_FILTER';
  name: FreeTextFilters;
  id: string;
}

export type TalentDiscoveryFilterAction =
  | AddCompanySearchFilterAction
  | AddFreeTextFiltersAction
  | AddRangeFilter
  | AddSchoolDetailSearchFilterAction
  | AddTreeFilterAction
  | RemoveCompanySearchFilterAction
  | RemoveFreeTextFilterAction
  | RemovePrimaryFilterAction
  | RemoveRangeFilter
  | RemoveSchoolDetailSearchFilterAction
  | RemoveSubFilterAction
  | RemoveTreeFilterAction
  | SetFiltersAction
  | UpdatePageAction
  | UpdateSortingAction;

export const initialTalentDiscoveryFilterState: TalentDiscoveryFilterState = {
  page: 1,
  filters: [],
  sorting: undefined,
};

const removeTreeItemChildren = (
  treeItems: Record<string, TreeItem> | undefined
): Record<string, TreeItem> | undefined => {
  if (!treeItems) {
    return undefined;
  }

  const newTreeItems = Object.entries(treeItems).reduce<
    Record<string, TreeItem>
  >((accumulator, [key, treeItem]) => {
    const { children, ...rest } = treeItem;
    accumulator[key] = { ...rest, children: [] };

    return accumulator;
  }, {});

  return newTreeItems;
};

export const talentDiscoveryFilterReducer = (
  state: TalentDiscoveryFilterState,
  action: TalentDiscoveryFilterAction
): TalentDiscoveryFilterState => {
  switch (action.type) {
    case 'OVERWRITE_FILTERS':
      return {
        page: state.page,
        filters: action.filters,
        sorting: undefined,
      };

    case 'ADD_RANGE_FILTER':
      return {
        ...state,
        filters: [
          ...(state.filters || []).filter(
            (filter) => filter.name !== action.name
          ),
          {
            name: action.name,
            start_value: action.start_value,
            end_value: action.end_value,
            isCurrent: action.isCurrent,
          },
        ],
      };
    case 'REMOVE_RANGE_FILTER':
      return {
        ...state,
        filters: state.filters?.filter((filter) => filter.name !== action.name),
      };
    case 'ADD_TREE_FILTER': {
      const existingFilterIndex = state.filters?.findIndex(
        (filter) => isTreeFilterState(filter) && filter.id === action.id
      );

      // replace filters
      if (action.id === null) {
        return {
          ...state,
          filters: [
            ...(state.filters || []).filter(
              (filter) => filter.name !== action.name
            ),
            {
              name: action.name,
              treeItems: removeTreeItemChildren(action.treeItems),
              isCurrent: action.isCurrent,
              id: `${Date.now()}`,
            },
          ],
        };
      }

      if (existingFilterIndex !== undefined && existingFilterIndex !== -1) {
        // Update existing filter
        const updatedFilters = [...(state.filters || [])];
        updatedFilters[existingFilterIndex] = {
          id: action.id,
          name: action.name,
          treeItems: removeTreeItemChildren(action.treeItems),
          isCurrent: action.isCurrent,
        };
        return {
          ...state,
          filters: updatedFilters,
        };
      } else {
        // Add new filter
        return {
          ...state,
          filters: [
            ...(state.filters || []),
            {
              id: action.id,
              name: action.name,
              treeItems: removeTreeItemChildren(action.treeItems),
              isCurrent: action.isCurrent,
            },
          ],
        };
      }
    }
    case 'REMOVE_TREE_FILTER':
      return {
        ...state,
        filters: state.filters?.filter((filter) => {
          if (isTreeFilterState(filter)) {
            return filter.id !== action.id;
          }
          return true;
        }),
      };
    case 'ADD_SEARCH_FILTER':
      return {
        ...state,
        filters: [
          ...(state.filters?.filter((filter) => filter.name !== action.name) ||
            []),
          {
            name: action.name,
            companyResultItems: action.companyResultItems,
            isCurrent: action.isCurrent,
          },
        ],
      };
    case 'REMOVE_SEARCH_FILTER':
      return {
        ...state,
        filters: state.filters?.filter((filter) => filter.name !== action.name),
      };
    case 'ADD_SCHOOL_DETAIL_SEARCH_FILTER':
      return {
        ...state,
        filters: [
          ...(state.filters?.filter((filter) => filter.name !== action.name) ||
            []),
          {
            name: action.name,
            schoolResultItems: action.schoolResultItems,
          },
        ],
      };
    case 'REMOVE_SCHOOL_DETAIL_SEARCH_FILTER':
      return {
        ...state,
        filters: state.filters?.filter((filter) => filter.name !== action.name),
      };
    case 'UPDATE_PAGE':
      return {
        ...state,
        page: action.page,
      };
    case 'UPDATE_SORTING':
      return {
        ...state,
        sorting: action.sorting,
      };
    case 'ADD_FREE_TEXT_FILTER': {
      const existingFilterIndex = state.filters?.findIndex(
        (filter) => isFreeTextFilterState(filter) && filter.id === action.id
      );

      //replace filters
      if (action.id === null) {
        return {
          ...state,
          filters: [
            ...(state.filters || []).filter(
              (filter) => filter.name !== action.name
            ),
            {
              name: action.name,
              text: action.text,
              id: `${Date.now()}`,
            },
          ],
        };
      }

      if (existingFilterIndex !== undefined && existingFilterIndex !== -1) {
        // Update existing filter
        const updatedFilters = [...(state.filters || [])];
        updatedFilters[existingFilterIndex] = {
          id: action.id,
          name: action.name,
          text: action.text,
        };
        return {
          ...state,
          filters: updatedFilters,
        };
      } else {
        // Add new filter
        return {
          ...state,
          filters: [
            ...(state.filters || []),
            {
              id: action.id,
              name: action.name,
              text: action.text,
            },
          ],
        };
      }
    }
    case 'REMOVE_FREE_TEXT_FILTER':
      return {
        ...state,
        filters: state.filters?.filter((filter) => {
          if (isFreeTextFilterState(filter)) {
            return filter.id !== action.id;
          }
          return true;
        }),
      };
    default:
      return state;
  }
};
