import {
  Link,
  Heading,
  VStack,
  chakra,
  useStyleConfig,
  Box,
  Divider,
} from '@chakra-ui/react';
import { RouteNavConfig } from '@revelio/auth';
import { NavLink, useLocation } from 'react-router-dom';
import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  MutableRefObject,
} from 'react';
import { createPortal } from 'react-dom';
import SubNavDropdown from '../sub-nav-dropdown/sub-nav-dropdown';
import { BehaviorSubject, isObservable } from 'rxjs';
import { catchError, distinct, tap } from 'rxjs/operators';
import { useEffect$ } from '@ngneat/react-rxjs';
import { FeatureFlag, PageTitles, tourStore } from '@revelio/core';
import { TourClasses } from '@revelio/core';
import React from 'react';
import { useUnleashFlag } from '../../../../hooks/unleash/useUnleashFlag';

export interface SubNavProps {
  parent: RouteNavConfig;
  items: RouteNavConfig[];
  sideNavRef: MutableRefObject<Element>;
}

export const Drawer = forwardRef<
  Element,
  { sideNavRef: MutableRefObject<Element>; children: JSX.Element }
>((props, ref) => {
  if (props.sideNavRef.current) {
    return createPortal(props.children, props.sideNavRef.current);
  }
  return null;
});

export const SubNav = forwardRef<Element, SubNavProps>(
  (props: SubNavProps, ref) => {
    const linkStyles = useStyleConfig('Link', { variant: 'nav' });
    const location = useLocation();
    const subNavOpenCtlr = useRef(new BehaviorSubject(false));
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const paneRef = useRef<HTMLDivElement>(null);
    const iconRef = useRef<HTMLAnchorElement>(null);

    const [isActive, setIsActive] = useState(false);
    const [isControlPanelSpotlighted, setIsControlPanelSpotlighted] =
      useState(false);

    const sideBarHoverDelayFeatureFlag = useUnleashFlag(
      FeatureFlag.SideBarHoverDelay
    );

    // Add a ref to store the timeout
    const hoverTimeoutRef = useRef<NodeJS.Timeout>();

    // Clean up timeout on unmount
    useEffect(() => {
      return () => {
        if (hoverTimeoutRef.current) {
          clearTimeout(hoverTimeoutRef.current);
        }
      };
    }, []);

    useEffect$(
      () =>
        tourStore.pipe(
          distinct(),
          tap((data) => {
            if (
              data.isTour &&
              props.parent.name ==
                TourClasses.TOUR_DELIVERABLES_SUB_NAV_CLASS &&
              !data.isSideBarCompany
            ) {
              setIsOpen(data.isSideBarOpen);
            }
            if (
              data.isTour &&
              props.parent.name == PageTitles.COMPANY &&
              data.isSideBarCompany
            ) {
              setIsOpen(data.isSideBarOpen);
            }
            setIsControlPanelSpotlighted(data.isControlPanelSpotlighted);
          }),
          catchError((e) => {
            return e;
          })
        ),
      [props.parent.name]
    );

    useEffect$(
      () =>
        subNavOpenCtlr.current
          .asObservable()
          .pipe(tap((val) => setIsOpen(val))),
      [subNavOpenCtlr.current]
    );

    useEffect(() => {
      if (location && props.items) {
        let match = false;
        setIsActive(
          props.items.some((item) => {
            if (item.path) {
              return (match = location.pathname === '/' + item.path);
            }
            if (item.children && !isObservable(item.children)) {
              item.children.some((child) => {
                if (child.path) {
                  return (match = location.pathname === '/' + child.path);
                }
                return (match = false);
              });
            }
            return match;
          })
        );
      }
    }, [location, props.items]);

    useEffect(() => {
      const checkIfClickedOutside: EventListener = (e) => {
        // If the menu is open and the clicked target is not within the menu,
        // then close the menu
        if (
          subNavOpenCtlr.current.value &&
          paneRef.current &&
          !paneRef.current.contains(e.target as Node) &&
          !(iconRef.current as Node).contains(e.target as Node)
        ) {
          subNavOpenCtlr.current.next(false);
        }
      };
      document.addEventListener('mouseover', checkIfClickedOutside);
      return () => {
        // Cleanup the event listener
        document.removeEventListener('mouseover', checkIfClickedOutside);
      };
    }, []);

    const firstItemIsSectionWithSubheading = props.items[0].subHeading;
    return (
      <>
        {props.parent.element ? (
          <Link
            as={NavLink}
            to={props.parent.path || ''}
            bgImg={`url(${props.parent.icon})`}
            variant="nav"
            onClick={() => {
              if (!isOpen) {
                subNavOpenCtlr.current.next(!subNavOpenCtlr.current.value);
              }
            }}
            ref={iconRef}
            // probably should use a js utility to conditionally join these cleaner
            className={`nav ${
              // eslint-disable-next-line no-nested-ternary
              isActive ? 'active' : isOpen ? 'semi-active' : ''
            }`}
          />
        ) : (
          <>
            {props.parent.divider && (
              <Divider
                color="white"
                opacity={0.3}
                width="70%"
                pointerEvents="none"
              />
            )}
            <chakra.a
              sx={linkStyles}
              // probably should use a js utility to conditionally join these cleaner
              data-testid="rl-sub-nav-link"
              className={`nav ${
                // eslint-disable-next-line no-nested-ternary
                isActive ? 'active' : isOpen ? 'semi-active' : ''
              }`}
              href={props.parent.path}
              bgImg={`url(${props.parent.icon})`}
              onMouseEnter={() => {
                // Set timeout to open after 200ms
                hoverTimeoutRef.current = setTimeout(
                  () => {
                    subNavOpenCtlr.current.next(true);
                  },
                  sideBarHoverDelayFeatureFlag ? 200 : 0
                );
              }}
              onMouseLeave={() => {
                // Clear timeout if mouse leaves before it triggers
                if (hoverTimeoutRef.current) {
                  clearTimeout(hoverTimeoutRef.current);
                }
              }}
              position="relative"
              h={props.parent.divider ? '6%' : '12%'}
              ref={iconRef}
              _before={
                isControlPanelSpotlighted && props.parent.isSourcesTab
                  ? {
                      content: `"${props.parent.name}"`,
                      position: 'absolute',
                      fontSize: '9px',
                      color: '#fff',
                      top: props.parent.name === 'Company' ? '100%' : '78%',
                      left: '-4px',
                      textAlign: 'center',
                      width: '120%',
                    }
                  : {}
              }
              _after={{
                content: '""',
                display: 'block',
                position: 'absolute',
                left: '7px',
                width: '48px',
                height: props.parent.divider ? '6vh' : '12vh',
                marginTop: '-20px',
              }}
            ></chakra.a>
          </>
        )}

        {
          <Drawer sideNavRef={props.sideNavRef}>
            <Box
              data-testid={`${props.parent.name}-subnav`}
              // visibility={isOpen ? 'visible' : 'hidden'}
              height="100vh"
              width="280px"
              position="absolute"
              background="white"
              left="100%"
              top="0"
              zIndex={isOpen ? 9999 : -10}
              padding="32px 24px"
              ref={paneRef}
              boxShadow="2xl"
              _after={{
                content: '""',
                position: 'absolute',
                top: (iconRef?.current?.offsetTop || 0) + 14 + 'px',
                right: '100%',
                borderWidth: '6px',
                borderStyle: 'solid',
                borderColor: 'transparent white  transparent transparent',
              }}
            >
              <VStack
                alignItems="flex-start"
                h="100%"
                overflowY="auto"
                className={
                  TourClasses.TOUR_DATA_SOURCES_CLASS +
                  `${props.parent.name ? ' ' + props.parent.name : ''}`
                }
              >
                {!firstItemIsSectionWithSubheading && (
                  <Heading color={'text.primary'} size="md" pb="2">
                    {props.parent.name}
                  </Heading>
                )}

                {props.items.map((item, i) => {
                  if (item.children) {
                    return (
                      <SubNavDropdown
                        key={i}
                        item={item}
                        subNavOpenCtlr={subNavOpenCtlr.current}
                        isParentOpen={isOpen}
                      />
                    );
                  }
                  return (
                    <>
                      {item.subHeading && (
                        <>
                          {!firstItemIsSectionWithSubheading && (
                            <Divider
                              borderColor="text.lowContrast"
                              opacity={1}
                              mt="5"
                              mb="6"
                            />
                          )}
                          <Heading color={'text.primary'} size="md" pb="2">
                            {item.subHeading}
                          </Heading>
                        </>
                      )}
                      {item.divider && (
                        <Divider
                          borderColor="text.lowContrast"
                          opacity={1}
                          mt="10px"
                          mb="2px"
                        />
                      )}
                      <Link
                        className="rl-sub-nav-link-item"
                        as={NavLink}
                        to={item.path as string}
                        key={i}
                        onClick={() => {
                          subNavOpenCtlr.current.next(false);
                        }}
                        fontSize="md"
                        padding="8px 12px"
                        marginTop="8px"
                        color={'text.primary'}
                        borderRadius={'md'}
                        width="100%"
                        _hover={{ background: 'green.100' }}
                        data-testid={`subnav-${item.parent ? `${item.parent}-` : ''}${item.name}`}
                      >
                        {item.name}
                      </Link>
                    </>
                  );
                })}
              </VStack>
            </Box>
          </Drawer>
        }
      </>
    );
  }
);

export default SubNav;
