import { ScreenerReqFilters, ScreenerReqSegments } from '@revelio/data-access';
import {
  ScreenerCompanyDetailCheckboxFilterState,
  ScreenerCompanyDetailFilterState,
  ScreenerCompanyDetailTreeFilterState,
  ScreenerCompanyDetailYearRangeFilterState,
  ScreenerCompanySearchFilterState,
  ScreenerFreeTextState,
  ScreenerRangeFilterState,
  ScreenerSchoolSearchFilterState,
} from './screener-filter-reducer';

export type PrimaryFilterValues = keyof Pick<
  ScreenerReqFilters,
  'industry' | 'region' | 'job_category'
>;

export type SubFilterValues = keyof ScreenerReqSegments;

export const companyDetailTreeFilters = [
  'founder_ethnicity',
  'founder_gender',
  'hq_location',
] as const;

export const companyDetailCheckboxFilters = [
  'funding_stage',
  'public',
  'valuation',
] as const;

export const companyDetailYearRangeFilters = [
  'last_funding_year',
  'year_founded',
] as const;

export const companyDetailRangeFilters = [
  'last_funding_amount',
  'total_funding_amount',
] as const;

export const companySearchFilters = [
  'competitors',
  'founder_previous_company',
] as const;

export const schoolSearchFilters = ['founder_previous_school'] as const;

export const freeTextFilters = ['keywords'] as const;

export type CompanyDetailTreeFilters =
  (typeof companyDetailTreeFilters)[number];

export type CompanyDetailCheckboxFilters =
  (typeof companyDetailCheckboxFilters)[number];

export type CompanyDetailYearRangeFilters =
  (typeof companyDetailYearRangeFilters)[number];

export type CompanyDetailRangeFilters =
  (typeof companyDetailRangeFilters)[number];

export type CompanySearchFilters = (typeof companySearchFilters)[number];

export type SchoolSearchFilters = (typeof schoolSearchFilters)[number];

export type FreeTextFilters = (typeof freeTextFilters)[number];

export interface ScreenerFilterOption {
  label: string;
  labelPostfix?: string;
  value:
    | PrimaryFilterValues
    | SubFilterValues
    | CompanyDetailTreeFilters
    | CompanyDetailCheckboxFilters
    | CompanyDetailYearRangeFilters
    | CompanyDetailRangeFilters
    | CompanySearchFilters
    | SchoolSearchFilters
    | FreeTextFilters
    | 'funding_info'
    | 'founder_info';
  primary?: boolean;
  companyDetail?: boolean;
  prefix?: string;
  filterGranularities?: string[];
  checkboxOptions?: { value: string; label: string }[];
  selectionLimit?: number;
  children?: ScreenerFilterOption[];
}

export type OperatorOption = {
  value: 'between' | 'greater_than' | 'less_than' | 'is';
  label: string;
};

const includesFilter = <T extends string>(
  filterArray: readonly T[],
  filter?: string
): filter is T => {
  return filter !== undefined && filterArray.includes(filter as T);
};

export const isCompanyDetailTreeFilter = (
  filter: string | undefined
): filter is CompanyDetailTreeFilters =>
  includesFilter(companyDetailTreeFilters, filter);

export const isCompanyDetailCheckboxFilter = (
  filter: string | undefined
): filter is CompanyDetailCheckboxFilters =>
  includesFilter(companyDetailCheckboxFilters, filter);

export const isCompanyDetailYearRangeFilter = (
  filter: string | undefined
): filter is CompanyDetailYearRangeFilters =>
  includesFilter(companyDetailYearRangeFilters, filter);

export const isCompanyDetailRangeFilter = (
  filter: string | undefined
): filter is CompanyDetailRangeFilters =>
  includesFilter(companyDetailRangeFilters, filter);

export const isCompanySearchFilter = (
  filter: string | undefined
): filter is CompanySearchFilters =>
  includesFilter(companySearchFilters, filter);

export const isSchoolSearchFilter = (
  filter: string | undefined
): filter is SchoolSearchFilters => includesFilter(schoolSearchFilters, filter);

export const isFreeTextFilter = (
  filter: string | undefined
): filter is FreeTextFilters => includesFilter(freeTextFilters, filter);

export const isRangeFilter = (
  state: ScreenerCompanyDetailFilterState | undefined
): state is ScreenerRangeFilterState =>
  companyDetailRangeFilters.includes(state?.name as CompanyDetailRangeFilters);

export const isCheckboxFilterState = (
  filter: ScreenerCompanyDetailFilterState | undefined
): filter is ScreenerCompanyDetailCheckboxFilterState =>
  filter?.name !== undefined &&
  companyDetailCheckboxFilters.includes(
    filter.name as CompanyDetailCheckboxFilters
  );

export const isTreeFilterState = (
  filter: ScreenerCompanyDetailFilterState | undefined
): filter is ScreenerCompanyDetailTreeFilterState =>
  filter?.name !== undefined &&
  companyDetailTreeFilters.includes(filter.name as CompanyDetailTreeFilters);

export const isYearRangeFilterState = (
  filter: ScreenerCompanyDetailFilterState | undefined
): filter is ScreenerCompanyDetailYearRangeFilterState =>
  filter?.name !== undefined &&
  companyDetailYearRangeFilters.includes(
    filter.name as CompanyDetailYearRangeFilters
  );

export const isCompanySearchFilterState = (
  filter: ScreenerCompanyDetailFilterState | undefined
): filter is ScreenerCompanySearchFilterState =>
  filter?.name !== undefined &&
  companySearchFilters.includes(filter.name as CompanySearchFilters);

export const isSchoolSearchFilterState = (
  filter: ScreenerCompanyDetailFilterState | undefined
): filter is ScreenerSchoolSearchFilterState =>
  filter?.name !== undefined &&
  schoolSearchFilters.includes(filter.name as SchoolSearchFilters);

export const isFreeTextFilterState = (
  filter: ScreenerCompanyDetailFilterState | undefined
): filter is ScreenerFreeTextState =>
  filter?.name !== undefined &&
  freeTextFilters.includes(filter.name as FreeTextFilters);

export const isRangeFilterState = (
  state: ScreenerCompanyDetailFilterState | undefined
): state is ScreenerRangeFilterState =>
  companyDetailRangeFilters.includes(state?.name as CompanyDetailRangeFilters);
