import {
  FeatureFlag,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
} from '@revelio/core';
import {
  FilterMenuLimits,
  FilterSets,
  GEOGRAPHY_GRANULARITY_FILTERS,
  LocalSelectionCategories,
  PrimaryFilterLimits,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
} from '@revelio/filtering';
import Overview from '../../../overview/overview';
import { useFlag } from '@unleash/proxy-client-react';
import { View } from '@revelio/data-access';

const title = [PageTitles.GEOGRAPHY, PageTitles.COMPOSITIONS];

const primaryViewFilters = [
  {
    filters: GEOGRAPHY_GRANULARITY_FILTERS,
    isNested: true,
  },
];

// const onlyConsiderTheseFiltersToTriggerDefaults = [
//   SelectionCategories.REGION,
//   SelectionCategories.METRO_AREA,
// ];

export function GeographyCompositions() {
  const keywordFiltersFeatureFlag = useFlag(FeatureFlag.GeoCompositionsKeyword);

  const SHARED_SELECTABLE_FILTERS = [
    SelectionCategories.HIGHEST_DEGREE,
    SelectionCategories.ETHNICITY,
    SelectionCategories.GENDER,
    SelectionCategories.INDUSTRY,
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    ...(keywordFiltersFeatureFlag
      ? [SelectionCategories.KEYWORD, SelectionCategories.RAW_TITLE]
      : []),
    LocalSelectionCategories.DATA_METRIC as unknown as SelectionCategories,
  ];

  const savedFilterSetFeatureFlag = useFlag(FeatureFlag.SavedSets);
  const savedFilterSet = savedFilterSetFeatureFlag
    ? [SelectionCategories.SAVED_FILTER_SET]
    : [];
  const selectableFilters = {
    snapshot: [
      ...savedFilterSet,
      SelectionCategories.SNAPSHOT_DATE,
      ...SHARED_SELECTABLE_FILTERS,
    ],
    overtime: [SelectionCategories.DATE_RANGE, ...SHARED_SELECTABLE_FILTERS],
  };

  return (
    <Overview
      title={title}
      primaryView={PrimaryView.GEOGRAPHY}
      savedSetView={View.Geography}
      viewType={ViewTypes.GEO}
      primaryFilter={PrimaryFilters.GEOGRAPHY}
      primaryViewFilterLimit={PrimaryFilterLimits.GEO_OVERVIEW}
      filterMenuLimit={FilterMenuLimits.GEO_OVERVIEW}
      primaryViewFilters={primaryViewFilters}
      selectableFilters={selectableFilters}
      sharedFilterSetId={FilterSets.GEOGRAPHY}
      filterSet={FilterSets.GEOGRAPHY_COMPOSITIONS}
    />
  );
}

export default GeographyCompositions;
