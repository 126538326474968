import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { StackProps, VStack } from '@chakra-ui/react';
import { SelectionCategories } from '../../../engine/filters.model';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useTypeaheadFetch } from '../../tree/async-tree/hooks/useTypeaheadFetch';
import { Subject, filter, map, pipe, withLatestFrom } from 'rxjs';
import { useClient } from 'urql';
import Autocomplete from '../../autocomplete/autocomplete';
import {
  getActiveFiltersState,
  useSingleOrMoreFilterState,
} from '../../../engine/filters.engine';
import { fetchNameRecommendationResults, isValidSearchString } from './helpers';
import { serializeTDFilters } from '../../../engine/filters.repository';
import {
  GetNameRecommendationsQuery,
  TalentDiscoveryV1_5Filter,
} from '@revelio/data-access';
import { TDStateConfig } from '../talent-discovery-filter-menu.config';

interface TDNameInputProps {
  state: TDStateConfig;
  setState: React.Dispatch<TDStateConfig>;
  setIsSubmitDisabled?: React.Dispatch<boolean>;
  isTypeaheadDisabled?: boolean;
  vStackProps?: StackProps;
}

const searchInput$ = new Subject<string>();

export const TDNameInput = ({
  state,
  setState,
  setIsSubmitDisabled,
  isTypeaheadDisabled = false,
  vStackProps,
}: TDNameInputProps) => {
  const onInputChange = (value: string) => {
    setState((prev: any) => {
      return {
        ...prev,
        [SelectionCategories.NAME]: value,
      };
    });

    searchInput$.next(value);
  };

  const [activeValue] = useSingleOrMoreFilterState(SelectionCategories.NAME);

  useEffect(() => {
    if (activeValue) {
      const activeName = get(activeValue, 'value', '');

      setState((prev: any) => {
        return {
          ...prev,
          [SelectionCategories.NAME]: activeName,
        };
      });
    }
  }, [activeValue, setState]);

  useEffect(() => {
    const isValidString = isValidSearchString(state?.name);

    setIsSubmitDisabled?.(!isValidString);
  }, [setIsSubmitDisabled, state?.name]);

  const gqlClient = useClient();

  const [recommendations, setRecommendations] = useState<
    { value: string; label: string }[]
  >([]);

  const { isLoading } = useTypeaheadFetch(searchInput$, {
    operatorsBeforeQuery: pipe(
      filter((searchString: string) => {
        if (isTypeaheadDisabled) {
          return false;
        }
        const isValidString = isValidSearchString(searchString);

        setRecommendations([]);

        return isValidString;
      }),
      withLatestFrom(getActiveFiltersState()),
      map(([searchString, filters]) => {
        return {
          searchString: searchString,
          filters: serializeTDFilters(filters),
        };
      })
    ),
    fetchResults: (source: {
      searchString: string;
      filters: TalentDiscoveryV1_5Filter;
    }) => {
      const { searchString, filters = {} } = source;

      const { name, ...restFilters } = filters;
      return fetchNameRecommendationResults(
        { name: searchString, ...restFilters, page: 1 },
        gqlClient
      );
    },
    operatorsAfterQuery: pipe(
      map((result: { data: GetNameRecommendationsQuery }) => {
        const retrievedData =
          result.data.talent_discovery_name_recommendations_v1_5 || [];

        const data = retrievedData.map((rec) => ({
          value: rec?.user_name,
          label: rec?.user_name,
        }));

        setRecommendations(data as { value: string; label: string }[]);
      })
    ),
  });

  return (
    <VStack my={2} {...vStackProps}>
      <FormControl isRequired>
        <FormLabel fontSize="sm">User Name</FormLabel>
        <Autocomplete
          inputId="td-name-search"
          placeholder="Search for a name"
          inputValue={state?.name}
          setInputValue={onInputChange}
          suggestions={recommendations}
          setSuggestions={setRecommendations}
          dropdownHeader="Suggestions"
          showLoader
          isLoading={isLoading}
          isTypeaheadDisabled={isTypeaheadDisabled}
        />
      </FormControl>
    </VStack>
  );
};
