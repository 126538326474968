import { Props, Select, SingleValue } from 'chakra-react-select';
import { ScreenerFilterOption } from './types';

export interface FilterSelectDropdownProps {
  filterOptions: ScreenerFilterOption[];
  selectedFilter: ScreenerFilterOption;
  onChangeSelectedFilter: (selected: SingleValue<ScreenerFilterOption>) => void;
  formatOptionLabel?: Props<ScreenerFilterOption>['formatOptionLabel'];
}

const flattenFilterOptions = (
  options: ScreenerFilterOption[]
): ScreenerFilterOption[] => {
  return options.reduce<ScreenerFilterOption[]>((acc, option) => {
    if (option.children) {
      return [...acc, ...flattenFilterOptions(option.children)];
    }

    return [...acc, option];
  }, []);
};

export const FilterSelectDropdown = ({
  filterOptions,
  selectedFilter,
  onChangeSelectedFilter,
  formatOptionLabel,
}: FilterSelectDropdownProps) => {
  const flatFilterOptions = flattenFilterOptions(filterOptions);

  return (
    <Select
      options={flatFilterOptions}
      value={selectedFilter}
      onChange={onChangeSelectedFilter}
      size="sm"
      formatOptionLabel={formatOptionLabel}
      selectedOptionColorScheme="green"
      autoFocus
      chakraStyles={{
        control: (provider) => ({
          ...provider,
          height: '30px',
          fontWeight: 400,
        }),
        option: (provider, state) => ({
          ...provider,
          fontWeight: 400,
          ...(state.isSelected && {
            '> p': { color: 'white' },
          }),
        }),
      }}
    />
  );
};
