import { Button, ButtonGroup, Flex } from '@chakra-ui/react';

export const SelectFooter = ({
  onClose,
  onClearSelections,
  onAdd = () => undefined,
  addLabel = 'Add',
  isAddDisabled = false,
  onBack,
}: {
  onClose: () => void;
  onClearSelections?: () => void;
  onAdd?: () => void;
  isAddDisabled?: boolean;
  addLabel?: string;
  onBack?: () => void;
}) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex>
        {onClearSelections && (
          <Button
            variant="ghost"
            size="sm"
            fontSize="xs"
            colorScheme="red"
            onClick={onClearSelections}
          >
            Clear Selections
          </Button>
        )}
        {onBack && (
          <Button
            variant="link"
            size="sm"
            fontSize="xs"
            fontWeight={600}
            colorScheme="gray"
            onClick={onBack}
          >
            Back
          </Button>
        )}
      </Flex>
      <ButtonGroup spacing={4}>
        <Button
          variant="link"
          size="sm"
          fontSize="xs"
          fontWeight={600}
          colorScheme="gray"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          data-testid="filter-popover-submit"
          isDisabled={isAddDisabled}
          colorScheme="green"
          variant="solid"
          size="sm"
          fontSize="xs"
          onClick={onAdd}
        >
          {addLabel}
        </Button>
      </ButtonGroup>
    </Flex>
  );
};
