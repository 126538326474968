import { FeatureFlag, PageTitles, PrimaryFilters, Views } from '@revelio/core';
import {
  ViewTypes,
  FilterSets,
  PrimaryFilterLimits,
  FilterMenuLimits,
  GEOGRAPHY_GRANULARITY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  INDUSTRY_AND_COMPANY_FILTERS,
  SelectionCategories,
} from '@revelio/filtering';
import { Sentiment } from '../../../sentiment/Sentiment';
import { useFlag } from '@unleash/proxy-client-react';
import { View } from '@revelio/data-access';

/* eslint-disable-next-line */
export interface RoleSentimentProps {}

export function RoleSentiment(props: RoleSentimentProps) {
  const title = [PageTitles.ROLE, PageTitles.SENTIMENT];
  const savedFilterSetFeatureFlag = useFlag(FeatureFlag.SavedSets);

  const primaryViewFilters = [
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 1,
    },
  ];
  const selectableFilters = [
    ...(savedFilterSetFeatureFlag
      ? [SelectionCategories.SAVED_FILTER_SET]
      : []),
    {
      filters: INDUSTRY_AND_COMPANY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.DATE_RANGE,
  ];

  return (
    <Sentiment
      title={title}
      view={Views.SENTIMENT}
      viewType={ViewTypes.ROLE}
      primaryFilter={PrimaryFilters.ROLE}
      sharedFilterSetId={FilterSets.ROLE}
      filterSet={FilterSets.ROLE_SENTIMENT}
      primaryViewFilters={primaryViewFilters}
      primaryFiltersLimit={PrimaryFilterLimits.SENTIMENT}
      selectableFilters={selectableFilters}
      filterMenuLimits={FilterMenuLimits.SENTIMENT}
      savedSetView={View.Role}
    />
  );
}

export default RoleSentiment;
