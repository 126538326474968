import { Flex } from '@chakra-ui/layout';
import { useMemo, useRef, useEffect } from 'react';
import {
  TalentDiscoveryFilterOption,
  isCompanySearchFilter,
  isSchoolSearchFilter,
  isFreeTextFilter,
  isRangeFilter,
  isTreeFilter,
} from '../types';
import { TreeFilter } from './tree-filter';
import { CompanySearchFilter } from './company-search-filter';
import { SchoolSearchFilter } from './school-search-filter';
import { FreeTextFilter } from './free-text-filter';
import { RangeFilter } from './range-filter';

export const TalenDiscoveryFilter = ({
  selectedFilter,
  closeMenu,
  filterId,
}: {
  selectedFilter: TalentDiscoveryFilterOption;
  closeMenu: () => void;
  filterId?: string;
}) => {
  const isATreeFilter = isTreeFilter(selectedFilter?.value);

  const isCompanySearch = isCompanySearchFilter(selectedFilter?.value);
  const isSchoolSearch = isSchoolSearchFilter(selectedFilter?.value);
  const isFreeTextSearchFilter = isFreeTextFilter(selectedFilter?.value);
  const isARangeFilter = isRangeFilter(selectedFilter?.value);

  const renderFilterComponent = useMemo(() => {
    if (isCompanySearch) {
      return (
        <CompanySearchFilter
          treeHeight={220}
          closeMenu={closeMenu}
          selectedFilter={selectedFilter}
        />
      );
    }

    if (isATreeFilter) {
      return (
        <TreeFilter
          selectionLists={selectedFilter.filterGranularities || []}
          closeMenu={closeMenu}
          selectedFilter={selectedFilter}
          filterId={filterId}
        />
      );
    }

    if (isSchoolSearch) {
      return (
        <SchoolSearchFilter
          treeHeight={220}
          closeMenu={closeMenu}
          selectedFilter={selectedFilter}
        />
      );
    }

    if (isFreeTextSearchFilter) {
      return (
        <FreeTextFilter
          closeMenu={closeMenu}
          selectedFilter={selectedFilter}
          filterId={filterId}
        />
      );
    }

    if (isARangeFilter) {
      return (
        <RangeFilter closeMenu={closeMenu} selectedFilter={selectedFilter} />
      );
    }

    return null;
  }, [
    isARangeFilter,
    isATreeFilter,
    isCompanySearch,
    isFreeTextSearchFilter,
    isSchoolSearch,
    closeMenu,
    filterId,
    selectedFilter,
  ]);

  // Handle click outside to close
  const componentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest('.chakra-popover__popper')
      ) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeMenu]);

  return (
    <Flex direction="column" gap="4" ref={componentRef}>
      {renderFilterComponent}
    </Flex>
  );
};
