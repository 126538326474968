import { Box, Button, Icon, useToast } from '@chakra-ui/react';
import { useUserTrialDetails } from '@revelio/auth';
import { downloadFile, WithTooltip } from '@revelio/core';
import { GO_API_ROOT, RequestMethod } from '@revelio/filtering';
import { useCallback, useEffect, useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import { useTalentDiscoveryFilter } from './filters/td-filter-provider';
import { serialiseTalentDiscoveryFilters } from './data-fetch/serialiseTalentDiscoveryFilters';
import { TalentDiscoveryV1_5Filter } from '@revelio/data-access';

export const TalentDiscoveryDownload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useTalentDiscoveryFilter();

  const [serialisedFilters, setSerialisedFilters] =
    useState<TalentDiscoveryV1_5Filter | null>(null);

  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: false,
  });

  useEffect(() => {
    let isMounted = true;

    const fetchSerializedFilters = async () => {
      try {
        const result = await serialiseTalentDiscoveryFilters(state.filters);
        if (isMounted) {
          setSerialisedFilters(result);
        }
      } catch (err) {
        if (isMounted) {
          console.error(err);
        }
      }
    };

    fetchSerializedFilters();

    // Cleanup function to prevent setting state on unmounted component
    return () => {
      isMounted = false;
    };
  }, [state.filters]);

  const toast = useToast();
  const triggerDownload = useCallback(async () => {
    if (isTrialUser) {
      return;
    }

    setIsLoading(true);
    try {
      const fileDownloadResponse = await fetch(
        `${GO_API_ROOT}/api/talent-discovery/downloads`,
        {
          method: RequestMethod.POST,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'x-request-id': crypto.randomUUID(),
          },
          body: JSON.stringify(serialisedFilters),
        }
      );
      if (!fileDownloadResponse.ok) {
        throw new Error(
          `Error: ${fileDownloadResponse.status} ${fileDownloadResponse.statusText}`
        );
      }

      const fileBlob = await fileDownloadResponse.blob();
      downloadFile({
        fileBlob,
        responseContentDisposition: fileDownloadResponse.headers.get(
          'Content-Disposition'
        ) as string,
      });
    } catch (e) {
      console.error(e);
      toast({
        title: 'Failed to download data',
        description:
          "We've run into an error and our team has been alerted. Please try again later.",
        status: 'error',
        duration: 4000,
        position: 'top-right',
      });
    }

    setIsLoading(false);
  }, [isTrialUser, toast, serialisedFilters]);

  return (
    <WithTooltip
      showCondition={isTrialUser}
      placement="top"
      variant="label"
      padding="10px 15px"
      label="This feature is not available for trial users"
      aria-label={`This feature is not available for trial users`}
    >
      <Box>
        {Object.keys(serialisedFilters || {})?.length > 0 ? (
          <Button
            isLoading={isLoading}
            loadingText="Preparing Download..."
            leftIcon={<Icon as={FiDownload} boxSize={3.5} />}
            size="sm"
            variant="white"
            onClick={triggerDownload}
            justifyContent="center"
            data-testid="download-data-button"
            isDisabled={isTrialUser}
          >
            Download Data
          </Button>
        ) : null}
      </Box>
    </WithTooltip>
  );
};
