import {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { get } from 'lodash';
import { filter, map, pipe } from 'rxjs';
import {
  CompanySearchTree,
  SelectionCategories,
  CompanyResultItem,
  TreeNode,
  useSelectionLists,
  arrayToTree,
  Item,
} from '@revelio/filtering';

export type ReportEntityCompanyEditorHandle = {
  handleClearSelections: () => void;
};

export interface ReportEntityCompanyEditorProps {
  limit: number;
  treeHeight: number;
  onSelectionsValue: (items: Record<string, CompanyResultItem>) => void;
  filterFn?: (item: Item) => boolean;
  searchInputRef?: MutableRefObject<HTMLInputElement | null>;
}

export const ReportEntityCompanyEditor = forwardRef<
  ReportEntityCompanyEditorHandle,
  ReportEntityCompanyEditorProps
>(
  (
    {
      limit,
      treeHeight,
      searchInputRef,
      onSelectionsValue,
      filterFn,
    }: ReportEntityCompanyEditorProps,
    ref
  ) => {
    const [placeholderList, setPlaceholderList] = useState<TreeNode[]>([]);
    const [selections, setSelections] = useState<
      Record<string, CompanyResultItem>
    >({});

    if (limit > 1) {
      throw new Error(
        "ReportEntityCompanyEditor doesn't currently support multiselect"
      );
    }

    useImperativeHandle(ref, () => ({
      handleClearSelections: () => {
        setSelections({});
      },
    }));

    useEffect(() => {
      onSelectionsValue && onSelectionsValue(selections);
    }, [onSelectionsValue, selections]);

    useSelectionLists(
      [SelectionCategories.COMPANY, SelectionCategories.INDUSTRY],
      undefined,
      pipe(filter((lists) => lists.length > 0)),
      pipe(
        map((res) => {
          if (res.error) {
            return;
          }
          let [companyList, industryList] = res;

          // If there's a filter function we need to filter the companies lists followed by any industries that do not have any companies under them
          if (filterFn) {
            companyList = {
              ...companyList,
              value: companyList.value.filter(filterFn),
            };
            const industriesWithCompanies: Record<string, true> = {};
            companyList.value.forEach((company: Item) => {
              industriesWithCompanies[String(company.industry)] = true;
            });
            industryList = {
              ...industryList,
              value: industryList.value.filter((industry: Item) => {
                return industriesWithCompanies[String(industry.id)];
              }),
            };
          }

          const { rootItems } = arrayToTree(companyList, industryList);
          setPlaceholderList(rootItems as TreeNode[]);
        })
      )
    );

    return (
      <CompanySearchTree
        filterFn={filterFn}
        isSingleSelect={true}
        placeholderListNodes={placeholderList}
        selections={selections}
        setSelections={(item) => {
          const id = item.rcid;
          setSelections((current) => {
            if (current[id]) {
              return {};
            }
            return {
              [id]: item,
            };
          });
        }}
        sortFn={(a: TreeNode, b: TreeNode) => {
          const headcountA = get(a, 'item.raw_emp_count');
          const headcountB = get(b, 'item.raw_emp_count');
          return headcountB - headcountA;
        }}
        searchInputRef={searchInputRef}
        treeHeight={treeHeight}
        treeNodeType={'checkboxes'}
      />
    );
  }
);
