import {
  Input,
  List,
  ListItem,
  PopoverBody,
  PopoverFooter,
  PopoverHeader,
} from '@chakra-ui/react';
import styles from './select-filter.module.scss';
import { ChangeEventHandler, Dispatch, SetStateAction, useState } from 'react';
import { TalentDiscoveryFilterOption } from './types';
import { SelectFooter } from './select-footer';

export const SelectFilter = ({
  filterOptions,
  setSelectedFilter,
  closeMenu,
}: {
  filterOptions: TalentDiscoveryFilterOption[];
  setSelectedFilter: Dispatch<
    SetStateAction<TalentDiscoveryFilterOption | null>
  >;
  closeMenu: () => void;
}) => {
  const [search, setSearch] = useState('');
  const [optionsStack, setOptionsStack] = useState<
    TalentDiscoveryFilterOption[][]
  >([filterOptions]);

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearch(e.target.value);
  };

  const currentOptions = optionsStack[optionsStack.length - 1];

  const filteredFilterOptions = currentOptions.filter((option) =>
    option.label.toLowerCase().includes(search.toLowerCase())
  );

  const handleFilterSelect = (filter: TalentDiscoveryFilterOption) => {
    if (filter.children) {
      setOptionsStack([...optionsStack, filter.children]);
      setSearch('');
    } else {
      setSelectedFilter(filter);
    }
  };

  const handleBack = () => {
    if (optionsStack.length > 1) {
      setOptionsStack(optionsStack.slice(0, optionsStack.length - 1));
      setSearch('');
    }
  };

  return (
    <>
      <PopoverHeader padding={0} borderBottomWidth={0}>
        <Input
          placeholder="Select a filter"
          size="sm"
          className={styles.input}
          onChange={handleSearch}
          value={search}
        />
      </PopoverHeader>
      <PopoverBody padding={0}>
        <List
          paddingY={2}
          border="1px solid #E5EBF1"
          borderTopWidth={0}
          overflowY="scroll"
          maxHeight="400px"
        >
          {filteredFilterOptions.length === 0 && (
            <ListItem paddingY={2} textAlign={'center'} opacity={0.5}>
              No options
            </ListItem>
          )}
          {filteredFilterOptions.map((option) => (
            <ListItem
              role="button"
              key={option.value}
              cursor={'pointer'}
              onClick={() => handleFilterSelect(option)}
              paddingX={3}
              paddingY={1.5}
              _hover={{ bg: 'gray.100' }}
              data-testid="select-filter-option"
            >
              {option.label}
            </ListItem>
          ))}
        </List>
      </PopoverBody>
      <PopoverFooter padding={'8px 0 0'} borderTopWidth={0}>
        <SelectFooter
          onClose={closeMenu}
          onBack={optionsStack.length > 1 ? handleBack : undefined}
          isAddDisabled
        />
      </PopoverFooter>
    </>
  );
};
