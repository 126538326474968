import {
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import { TalentDiscoveryFilterOption } from './types';
import { SelectFilter } from './select-filter';
import { FilterSelectDropdown } from './filter-select-dropdown';
import { TalenDiscoveryFilter } from './selection-forms/talent-discovery-filter';
import { FILTER_OPTIONS } from './filter-configuration-options';
import {
  SelectionCategories,
  useRestrictGenderAndEthnicityFilters,
} from '@revelio/filtering';

interface TalentDiscoveryFilterPopoverProps {
  trigger: ReactNode;
  selectedFilterToEdit?: TalentDiscoveryFilterOption;
  filterId?: string;
}

export const TalentDiscoveryFilterPopover = ({
  trigger,
  selectedFilterToEdit,
  filterId,
}: TalentDiscoveryFilterPopoverProps) => {
  const [localSelectedFilter, setSelectedFilter] =
    useState<TalentDiscoveryFilterOption | null>(null);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

  const selectedFilter = selectedFilterToEdit || localSelectedFilter;

  const handleOpenFilterMenu = () => {
    setIsFilterMenuOpen(true);
  };

  const handleClose = () => {
    setIsFilterMenuOpen(false);

    setTimeout(() => {
      setSelectedFilter(null);
    }, 100); // wait for the popover to close
  };

  const handleCloseFilterMenu = () => {
    //opening nested tree triggers close without this
    if (selectedFilter) {
      return;
    }

    handleClose();
  };

  const restrictGenderAndEthnicity = useRestrictGenderAndEthnicityFilters();

  const filterOptions = restrictGenderAndEthnicity
    ? FILTER_OPTIONS.filter(
        (option) =>
          ![SelectionCategories.ETHNICITY, SelectionCategories.GENDER].includes(
            option.value as SelectionCategories
          )
      )
    : FILTER_OPTIONS;

  return (
    <Popover
      placement="bottom-start"
      closeOnBlur
      onClose={handleCloseFilterMenu}
      isOpen={isFilterMenuOpen}
      onOpen={handleOpenFilterMenu}
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent
        minWidth="325px"
        padding={3}
        color="navyBlue.500"
        boxShadow="2xl"
        data-testid={`td-filter-popover-content-${isFilterMenuOpen ? 'open' : 'closed'}`}
      >
        {(() => {
          if (!isFilterMenuOpen) {
            return null;
          }

          if (!selectedFilter) {
            return (
              <SelectFilter
                filterOptions={filterOptions}
                setSelectedFilter={setSelectedFilter}
                closeMenu={handleCloseFilterMenu}
              />
            );
          }

          if (selectedFilter) {
            return (
              <div data-testid="filter-menu-popover">
                <PopoverHeader
                  marginBottom={2}
                  padding={0}
                  fontWeight="semibold"
                  borderBottomWidth={0}
                >
                  {!selectedFilterToEdit && (
                    <FilterSelectDropdown
                      filterOptions={filterOptions}
                      selectedFilter={selectedFilter}
                      onChangeSelectedFilter={setSelectedFilter}
                    />
                  )}
                </PopoverHeader>
                <TalenDiscoveryFilter
                  selectedFilter={selectedFilter}
                  closeMenu={handleClose}
                  filterId={filterId}
                />
              </div>
            );
          }

          return null;
        })()}
      </PopoverContent>
    </Popover>
  );
};
