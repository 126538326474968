import {
  FeatureFlag,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
} from '@revelio/core';
import {
  SelectionCategories,
  ViewTypes,
  FilterSets,
  PrimaryFilterLimits,
  FilterMenuLimits,
  GEOGRAPHY_GRANULARITY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  INDUSTRY_AND_COMPANY_FILTERS,
  useAdaptiveRoleTaxonomy,
} from '@revelio/filtering';
import Compensation from '../../../compensation/compensation';
import { View } from '@revelio/data-access';
import { useFlag } from '@unleash/proxy-client-react';

const title = [PageTitles.COMPANY, PageTitles.PAY];

const primaryViewFilters = [
  {
    filters: INDUSTRY_AND_COMPANY_FILTERS,
    isNested: true,
    limit: 2,
  },
];

const primaryFilters = primaryViewFilters.reduce((acc, f) => {
  return [...acc, ...f.filters];
}, [] as SelectionCategories[]);

export function CompanyPay() {
  const viewType = ViewTypes.COMPANY;
  const savedFilterSetFeatureFlag = useFlag(FeatureFlag.SavedSets);
  useAdaptiveRoleTaxonomy({
    viewType,
    primaryFilters: primaryFilters,
  });

  const selectableFilters = [
    ...(savedFilterSetFeatureFlag
      ? [SelectionCategories.SAVED_FILTER_SET]
      : []),
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    SelectionCategories.DATE_RANGE_FULL,
  ];

  return (
    <Compensation
      title={title}
      primaryView={PrimaryView.COMPANY}
      viewType={ViewTypes.COMPANY}
      primaryFilter={PrimaryFilters.COMPANY}
      sharedFilterSetId={FilterSets.COMPANY}
      filterSet={FilterSets.COMPANY_COMPENSATION}
      primaryViewFilters={primaryViewFilters}
      primaryFiltersLimit={PrimaryFilterLimits.COMPENSATION}
      selectableFilters={selectableFilters}
      filterMenuLimits={FilterMenuLimits.COMPENSATION}
      disableParentOnPrimaryFilter={false}
      otherFilters={[]}
      savedSetView={View.Company}
    />
  );
}

export default CompanyPay;
